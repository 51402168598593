import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'day-times',
  templateUrl: './day-times.component.html',
  styleUrls: ['./day-times.component.scss'],
})
export class DayTimesComponent implements OnInit {

  @Input() day: string;
  @Input() list: any; 

  constructor() { }

  ngOnInit() {}

  convertToDate(str)
  {
    let d = new Date();
    let s = new String(str);

    let hrs =  s.substr(0,2);
    let min = s.substr(3,2);
    d.setHours(parseInt(hrs));
    d.setMinutes(parseInt(min));
    
    return d;
  }
}
