import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'transaction-summary',
  templateUrl: './transaction-summary.component.html',
  styleUrls: ['./transaction-summary.component.scss'],
})
export class TransactionSummaryComponent implements OnInit {
  @Input() stuff: any;
  data: any = {};
  constructor() { 
    
  }

  ngOnInit() {
    this.data = this.stuff;
    console.log("summary is " + JSON.stringify(this.data));
  }

}
