import { Geolocation } from "@ionic-native/geolocation/ngx";
import { Diagnostic } from "@ionic-native/diagnostic/ngx";
import { TransactionSummaryComponent } from "./component/transaction-summary/transaction-summary.component";

import { FindMassService } from "./providers/find-mass.service";
import { KeypadComponent } from "./component/keypad/keypad.component";
import { RecurringOptionsComponent } from "./component/recurring-options/recurring-options.component";
import { SharedModule } from "./component/shared.module";
import { PaymethodComponent } from "./component/pay/paymethod/paymethod.component";
import { HttpClientModule, HttpClientJsonpModule } from "@angular/common/http";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { InAppBrowser } from "@ionic-native/in-app-browser/ngx";
import { SplashScreen } from "@ionic-native/splash-screen/ngx";
import { StatusBar } from "@ionic-native/status-bar/ngx";
import { IonicModule } from "@ionic/angular";
import { IonicStorageModule } from "@ionic/storage";

import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { ServiceWorkerModule } from "@angular/service-worker";
import { environment } from "../environments/environment";
import { OneSignal } from "@ionic-native/onesignal/ngx";
import { FirebaseUIModule, firebase, firebaseui } from "firebaseui-angular";

import { AngularFireModule } from "@angular/fire";
import { AngularFireFunctionsModule } from '@angular/fire/functions';
import { AngularFireAuthModule } from "@angular/fire/auth";
import { AngularFireDatabaseModule } from "@angular/fire/database";
import { AngularFirestoreModule } from "@angular/fire/firestore";

import { IonicSelectableModule } from "ionic-selectable";

import { FormsModule } from "@angular/forms";
import { ChurchDetailsHeaderComponent } from "./component/church-details-header/church-details-header.component";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";

// plugins
import { AppRate } from "@ionic-native/app-rate/ngx";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { AppVersion } from "@ionic-native/app-version/ngx";
import { Calendar } from "@ionic-native/calendar/ngx";
import { EmailComposer } from "@ionic-native/email-composer/ngx";
import { LaunchNavigator } from "@ionic-native/launch-navigator/ngx";
import { Deploy } from "cordova-plugin-ionic/dist/ngx";
import { LocationAccuracy } from "@ionic-native/location-accuracy/ngx";
import { AndroidPermissions } from "@ionic-native/android-permissions/ngx";
import { Network } from "@ionic-native/network/ngx";
import { AngularFireStorage } from "@angular/fire/storage";
import { AdminSpecialPipe } from "./pages/admin-special.pipe";

//import samlProvider from "./samlAuth";

// import { FCM } from '@ionic-native/fcm/ngx';
// import { FirebaseMessaging} from "@ionic-native/firebase-messaging/ngx";
//import samlProvider from "./samlAuth/index";
import * as samlProvider from "./samlAuth"
import * as samlProviderDev from "./samlAuth/index.dev";

export function redirectFunction(authResult, redirectUrl) {
  return false;
}

 //const samlProvider = new firebase.auth.SAMLAuthProvider('saml.maitland-dev').providerId;
// const clientSamlProvider = new firebase.auth.SAMLAuthProvider('saml.good-giving-test-kesorn-2').providerId
const firebaseUiAuthConfig: firebaseui.auth.Config = {
  callbacks: {
    signInSuccessWithAuthResult: redirectFunction,
    // uiShown: function() {
    //   console.log("firebase ui rendered")
    //   // The widget is rendered.
    //   // Hide the loader.
    //   //document.getElementById('loader').style.display = 'none';
    // }
  },
  signInSuccessUrl:  "/parish-admin", //window.location.href.toString().includes("tutorial") ? null : "/home",
  signInFlow: "popup",
  signInOptions: [
    // {
    //   provider: firebase.auth.PhoneAuthProvider.PROVIDER_ID,
    //   whitelistedCountries: ['AU', '+61']
    // },
    {provider:firebase.auth.EmailAuthProvider.PROVIDER_ID}
    // ,{
    //   provider: clientSamlProvider
    // },
    , samlProvider.default
    // {
    //   provider: firebase.auth.FacebookAuthProvider.PROVIDER_ID,
    //   scopes: [
    //     'public_profile',
    //     'email'
    //   ]
    // },
    // firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  tosUrl: "/tos",
  privacyPolicyUrl: "/privacy-policy",
  credentialHelper: firebaseui.auth.CredentialHelper.NONE,
};

@NgModule({
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientJsonpModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    IonicModule.forRoot(),
    IonicStorageModule.forRoot(),
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.production,
    }),
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireFunctionsModule,
    AngularFirestoreModule,
    AngularFireDatabaseModule,
    AngularFireAuthModule,
    FirebaseUIModule.forRoot(firebaseUiAuthConfig),
    IonicSelectableModule,
    NgxDatatableModule,
  ],
  entryComponents: [
    ChurchDetailsHeaderComponent,
    RecurringOptionsComponent,
    KeypadComponent,
    TransactionSummaryComponent,
  ],
  declarations: [AppComponent, PaymethodComponent, AdminSpecialPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    InAppBrowser,
    SplashScreen,
    StatusBar,
    FindMassService,
    AppRate,
    CallNumber,
    AppVersion,
    Calendar,
    EmailComposer,
    AngularFireStorage,
    LaunchNavigator,
    Deploy,
    OneSignal,
    AndroidPermissions,
    LocationAccuracy,
    Diagnostic,
    Geolocation,
    Network,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
