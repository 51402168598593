import { LaunchNavigator } from "@ionic-native/launch-navigator/ngx";
import { CallNumber } from "@ionic-native/call-number/ngx";
import { List } from "linqts";
import { FindMassService } from "./../../providers/find-mass.service";
import { Component, OnInit, Input } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { EmailComposer } from "@ionic-native/email-composer/ngx";
@Component({
  selector: "admin-edit",
  templateUrl: "./admin-edit.component.html",
  styleUrls: ["./admin-edit.component.scss"],
})
export class AdminEditComponent implements OnInit {
  cd: any = {
    name: "loading",
    address: "loading",
    priest: "loading",
    website: "loading",
    email: "loading",
    phone: "loading",
  };
  myID: string;
  findm: FindMassService;
  mts: any;
  showMass: boolean = true;
  showReco: boolean = true;
  showOther: boolean = true;
  @Input() id = 0;
  callNumber: any;
  emailComposer: EmailComposer;
  launchnavigator: LaunchNavigator;
  constructor(
    public findms: FindMassService,
    private activatedRoute: ActivatedRoute,
    public callN: CallNumber,
    public ec: EmailComposer,
    public ln: LaunchNavigator
  ) {
    this.emailComposer = ec;
    this.myID = this.id.toString();
    this.findm = findms;
    this.callNumber = callN;
    this.launchnavigator = ln;
    //this.report();
  }

  ngOnInit() {
    this.myID = this.activatedRoute.snapshot.paramMap.get("churchid");
    console.log("church detials loaded with " + this.myID);
    this.report();
  }

  mass: any = [];
  reco: any = [];
  other: any = [];
  days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  allData = {
    1: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
    2: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
    3: {
      0: [],
      1: [],
      2: [],
      3: [],
      4: [],
      5: [],
      6: [],
    },
  };

  massList = [];
  recoList = [];
  otherList = [];
  massMondayList = [];
  allMasses = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };
  allReco = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };
  allOther = {
    Sunday: [],
    Monday: [],
    Tuesday: [],
    Wednesday: [],
    Thursday: [],
    Friday: [],
    Saturday: [],
  };
  report() {
    let data = this.findm.getChurchDetails(this.myID)[0]; //.ChurchData[0];
    if (data) {
      //alert("there is data " + JSON.stringify(data));
      this.cd = data.ChurchData[0];
      this.mts = data.MassTimes;

      this.mts.forEach((element) => {
        if (parseInt(element.Type) > 2) {
          element.Type = 3; // grouping all 'others' with Adoration
        }
        this.allData[parseInt(element.Type)][parseInt(element.Day)] = element;
      });
      console.log(this.allData);
      this.massList = new List(this.mts).Where((x) => x["Type"] == 1).ToArray();
      this.recoList = new List(this.mts).Where((x) => x["Type"] == 2).ToArray();
      this.otherList = new List(this.mts)
        .Where((x) => x["Type"] == 3)
        .ToArray();

      this.allMasses.Sunday = new List(this.massList)
        .Where((x) => x["Day"] == 0)
        .ToArray();
      this.allMasses.Monday = new List(this.massList)
        .Where((x) => x["Day"] == 1)
        .ToArray();
      this.allMasses.Tuesday = new List(this.massList)
        .Where((x) => x["Day"] == 2)
        .ToArray();
      this.allMasses.Wednesday = new List(this.massList)
        .Where((x) => x["Day"] == 3)
        .ToArray();
      this.allMasses.Thursday = new List(this.massList)
        .Where((x) => x["Day"] == 4)
        .ToArray();
      this.allMasses.Friday = new List(this.massList)
        .Where((x) => x["Day"] == 5)
        .ToArray();
      this.allMasses.Saturday = new List(this.massList)
        .Where((x) => x["Day"] == 6)
        .ToArray();

      this.allReco.Sunday = new List(this.recoList)
        .Where((x) => x["Day"] == 0)
        .ToArray();
      this.allReco.Monday = new List(this.recoList)
        .Where((x) => x["Day"] == 1)
        .ToArray();
      this.allReco.Tuesday = new List(this.recoList)
        .Where((x) => x["Day"] == 2)
        .ToArray();
      this.allReco.Wednesday = new List(this.recoList)
        .Where((x) => x["Day"] == 3)
        .ToArray();
      this.allReco.Thursday = new List(this.recoList)
        .Where((x) => x["Day"] == 4)
        .ToArray();
      this.allReco.Friday = new List(this.recoList)
        .Where((x) => x["Day"] == 5)
        .ToArray();
      this.allReco.Saturday = new List(this.recoList)
        .Where((x) => x["Day"] == 6)
        .ToArray();

      this.allOther.Sunday = new List(this.otherList)
        .Where((x) => x["Day"] == 0)
        .ToArray();
      this.allOther.Monday = new List(this.otherList)
        .Where((x) => x["Day"] == 1)
        .ToArray();
      this.allOther.Tuesday = new List(this.otherList)
        .Where((x) => x["Day"] == 2)
        .ToArray();
      this.allOther.Wednesday = new List(this.otherList)
        .Where((x) => x["Day"] == 3)
        .ToArray();
      this.allOther.Thursday = new List(this.otherList)
        .Where((x) => x["Day"] == 4)
        .ToArray();
      this.allOther.Friday = new List(this.otherList)
        .Where((x) => x["Day"] == 5)
        .ToArray();
      this.allOther.Saturday = new List(this.otherList)
        .Where((x) => x["Day"] == 6)
        .ToArray();

      console.log("XXX" + this.allMasses.Sunday.length);

      console.log(this.cd);
      console.log(this.mts);
    } else {
      console.log("no data so returning ");
      return;
    }
  }
  selectedWor: number = 0;
  worshipTypeChanged(val) {
    this.selectedWor = parseInt(val.detail.value);
    switch (this.selectedWor) {
      case 1: {
        this.showMass = true;
        this.showReco = false;
        this.showOther = false;
        return;
      }
      case 2: {
        this.showMass = false;
        this.showReco = true;
        this.showOther = false;
        return;
      }
      case 3: {
        this.showMass = false;
        this.showReco = false;
        this.showOther = true;
        return;
      }
      case 0: {
        this.showMass = true;
        this.showReco = true;
        this.showOther = true;
      }
    }
  }
  toggleReco() {
    this.showReco = !this.showReco;
  }

  callPhone() {
    //this.callNumber.this.cd.phone

    this.callNumber
      .callNumber(this.cd.phone, true)
      .then((res) => console.log("Launched dialer!", res))
      .catch((err) => console.log("Error launching dialer" + err));
  }

  sendEmail() {
    this.emailComposer.isAvailable().then((available: boolean) => {
      if (available) {
        //Now we know we can send
      }
    });

    let email = {
      to: "reception@adelaide.catholic.org.au",
      //cc: 'erika@mustermann.de',
      bcc: ["support@dxts.com.au"],
      //  attachments: [
      //    'file://img/logo.png',
      //    'res://icon.png',
      //    'base64:icon.png//iVBORw0KGgoAAAANSUhEUg...',
      //    'file://README.pdf'
      //  ],
      subject: "Error on app for Mass center:" + this.cd.name,
      body: "Hi, I have notice and error on the app, it says <insert orginal> but it should be <insert correction>. Thank you, <name>",
      isHtml: true,
    };

    // Send a text message using default options
    if (this.emailComposer) {
      this.emailComposer.open(email);
    }
  }

  openNav() {
    navigator.geolocation.getCurrentPosition((loc) => {
      if (this.launchnavigator) {
        this.launchnavigator.navigate([this.cd.lat, this.cd.lon], {
          start:
            loc.coords.latitude.toString() +
            "," +
            loc.coords.longitude.toString(),
        });
      }
    });
  }
}
