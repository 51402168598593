import { UserService } from './user.service';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';

@Injectable({
  providedIn: 'root'
})
export class PayMethodService {

  constructor(private firestore: AngularFirestore, public userService: UserService) { 
    
  }

  create_pending_User (userid, pending_details) {
    let userRef =  this.firestore.collection('users').doc(userid);

    // Atomically add a new region to the "regions" array field.
    return userRef.update({
      "pending": firebase.firestore.FieldValue.arrayUnion(pending_details)
    });
  }

  create_cc_User (userid, cc_details, makeDefault) {
    let userRef =  this.firestore.collection('users').doc(userid);

    
    if (makeDefault || this.userService.get_User_selectedCC() == "") {
      this.userService.set_User_defaultCCToken(userid, cc_details.token);
    }

    // Atomically add a new region to the "regions" array field.
    if (makeDefault)
    {
      return userRef.update({
        "selectedCC" : cc_details.token,
        "selectedExpDate": cc_details.card_expiry,
        "cclist": firebase.firestore.FieldValue.arrayUnion(cc_details)
      });
    }
    else
    {
      return userRef.update({        
        "cclist": firebase.firestore.FieldValue.arrayUnion(cc_details)
      });
    }

  }

  delete_cc_User(userid, cc_details) {
    let userRef =  this.firestore.collection('users').doc(userid);

    // Atomically add a new region to the "regions" array field.
    return userRef.update({
      "cclist": firebase.firestore.FieldValue.arrayRemove(cc_details)
    });
  }

  // returns a list of CCs for a user (token, card number, name, exp date)
  get_cc_User(userid) {
    let userRef =  this.firestore.collection('users').doc(userid);
    
    userRef.get().forEach(documentSnapshot => {
        console.log(`Found document at ${documentSnapshot.ref.path}`);
      });
    };
    
  get_selectedCC_User(userid, callback) {
    let userRef =  this.firestore.collection('users').doc(userid);
    return userRef.ref.get().then(function(doc) {
      if (doc.exists) {
          let userData = doc.data();  
          callback(userData.selectedCC);        
      }
    });
  }
}
