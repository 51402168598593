import { AdminUserService } from './../../providers/admin-user.service';
import { DataServiceService } from './../../providers/data-service.service';
import { UserService } from './../../providers/user.service';
import { Component, OnInit, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { IonicSelectableComponent } from 'ionic-selectable';
// https://www.npmjs.com/package/ionic-selectable

@Component({
  selector: 'parish-selector',
  templateUrl: './parish-selector.component.html',
  styleUrls: ['./parish-selector.component.scss'],
})
export class ParishSelectorComponent implements OnInit {
  @Input() diocese:number; // determine which parish list to get.
  @Input() setParish: any;
  @Input() selectMultiple:boolean = false;
  @Output() selected = new EventEmitter<number>();
  @Output() type = new EventEmitter<string>();
  @Output() parishSelected = new EventEmitter<any>();
  selectedParishId: number = -1;
  selectedParishType: string = "diocese";
  dioceseId: number = 5;
  selectedParish: any = {};
  parishes: any[] = [
    {
      id: 1,
      first: 'Henley Beach Parish',
      last: 'MSC',          
    },
    {
      id: 2,
      first: 'Adelaide Cathedral Parish',
      last: 'Diocese',
    },
    {
      id: 3,
      first: 'Brighton Parish',
      last: 'Diocese',
    }
  ];

  parishNoDiocese = [1];
  myUserService: UserService;

  @ViewChild('searchComponent', {static: false}) portComponent: IonicSelectableComponent;


  constructor(
    userService: UserService, public appData: DataServiceService, adminUser: AdminUserService
  ) { 
    
    this.fullParishList = appData.getParishStaticData(adminUser.getClientID());
    // appData.getParishListFB().then( (d) => {
    //   this.fullParishList = d;

    //   console.log("updated parish selector with latest data");
    // }).catch( err => {

    // }).finally( () => {
    //   this.myUserService = userService;
    //   let pd = userService.get_user_parishDetails();
    //   try 
    //   {
    //     if (pd)
    //     {
    //       this.selectedParish = pd;
    //       this.parishSelected.emit(pd);
    //     }
    //     else
    //     {
    //       this.selectedParish = this.setParish;
    //     }
    //   }catch(ex){
    //     console.log("Could not put default value into parish selector");
    //   }
    // });    
  }

  setUserParish(parishObj) {
      this.selectedParish = parishObj;
  };

  ngOnInit() {}

  parishChanged($event){
    //console.log(JSON.stringify($event.value));
    //this.selectedParishId = $event;
    this.selectedParish = $event.value;
    this.parishSelected.emit($event.value);
  }

  openSearch($event) {
    this.portComponent.open();
  }
  
  fullParishList: any = [
    {
      "ID": 9999999999,
      "name": "Parishes are loading..",
      "first": 100014015,
      "second": 100000009,
      "other": 100013525,
      "abn": 11083216579,
      "active": 1,
      "diocese": 5
    }
  ];
  
}
