import { RecurringOptionsComponent } from './../component/recurring-options/recurring-options.component';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import { firebase } from 'firebaseui-angular';

import { UserFB, Donation } from './../interfaces/user-options';
import { Injectable } from '@angular/core';

import { AngularFirestore } from '@angular/fire/firestore';
import { LoadingController } from '@ionic/angular';
 
@Injectable({
  providedIn: 'root'
})
export class UserService {

  theUser: UserFB = {
    username: "",
    password: "",
    name: "",
    surname: "",
    number: "",
    address: "",
    parish: "",
    parishDetails: {},
    email: "",
    clientId: 0, 
    selectedCC: "",
    selectedExpiry: "",
    cclist: [],
    transactions: [],
    pending: [],
    recurring: ""
  };

  tempDonation: {
    // build up donation details
    rrule:"",
    recurringStartDate:"",    
    recurringType: "";
    dioceseId: 5;
    parishId:  0;
    parishValue:  5;
    dioceseValue: 5;
    thirdId: 0;
    thirdValue:  0;
    total: -1;
    clientId: 5;
    selectedParishId:0,
    otherId: 0
  }

  http: HttpClient;
  SERVER_URL = environment.SERVER;
  loading: any ;

  constructor(
    private firestore: AngularFirestore,
    public ht: HttpClient,
    public loadingController: LoadingController
  ) { 
    this.http = ht;
    //debugger;
    console.log("@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@  server is " + this.SERVER_URL)
  }
 

  private httpGet(url: string, token: any, callback: any) {
    this.presentLoading();
    this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',// 'attachment/csv',
        'Authorization': token
      })
    }).subscribe(res => {
      if (this.loading){
        this.loading.dismiss();
      }
      callback(res);
    });
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait while the report generates .. ',
      duration: 2000
    });
    await this.loading.present();
    const { role, data } = await this.loading.onDidDismiss();    
  }

  private getCSV(url){
    var hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:attachment/csv,' + encodeURI(url);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'myFile.csv';
    hiddenElement.click();
  }
  create_update_User(userid, record){
      return this.firestore.collection('users').doc(userid).set(record,  {"merge": true});
  }

  clear() {
    window.localStorage.removeItem("currentUser");
    this.theUser = {
      username: "",
      password: "",
      name: "",
      surname: "",
      number: "",
      address: "",
      parish: "",
      parishDetails: {},
      email: "",
      clientId: 0, 
      selectedCC: null,
      selectedExpiry: "",
      cclist: [],
      transactions: [],
      pending: [],
      recurring: ""
    };


    this.tempDonation =  {
      // build up donation details
      rrule: "",      
      recurringStartDate:"",
      recurringType: "",
      dioceseId: 5,
      parishId:  0,
      parishValue:  5,
      dioceseValue: 5,
      thirdId: 0,
      thirdValue:  0,
      total: -1,
      clientId: 5,
      selectedParishId: 0,
      otherId:0
    }
  }
  private authToken: string = null;
  setToken(tok) {
    this.authToken = tok;
  }


  setDonation(don) {

    this.tempDonation = don;
    
  }

  getDonation() {
    return this.tempDonation;
  }

  setClientId(val) {
    this.theUser.clientId = val;
    this.updateLocal();
  }

  getClientId() {
    return this.theUser.clientId;
  }

  saveDonation(userid, sqlId, don) {
    don["sqlId"] = sqlId;
    var userRef = this.firestore.collection("users").doc(userid);    
      return userRef.update({
        recurring: don
      });    
  }
  deleteRecurring(userid) {
    var userRef = this.firestore.collection("users").doc(userid);
    return userRef.update({
      recurring: null
    });    
  }

  get_User_local() {
    let userData = window.localStorage.getItem("currentUser");
    if (userData != null)
    {
      return JSON.parse(userData);
    }
    else {
      return null;
    }
  }

  get_User_selectedCC() {
    
    let usr = this.get_User_local()
    if (usr) 
    {
      return usr.selectedCC;
    }
    return null;
  }

  get_user_parishDetails() {
    let usr = this.get_User_local()
    if (usr) 
    {
      return usr.parishDetails;
    }
    return null;
  }

  updateLocal() {
    window.localStorage.setItem("currentUser", JSON.stringify(this.theUser));  
  }

  load_User(userid, callback){
    var docRef = this.firestore.collection("users").doc(userid);
    docRef.ref.get().then(doc => {
      let d = doc.data();  
      this.theUser = {
        username: "",
        password: "",
        name: d.name,
        surname: d.surname,
        number: d.number,
        address: d.address,
        parish: d.parish,
        parishDetails: d.parishDetails,
        email: d.email,
        clientId: d.clientId, 
        selectedCC: d.selectedCC,
        selectedExpiry: d.selectedExpiry ? d.selectedExpiry:"",
        cclist: d.cclist,
        transactions: d.transactions,
        pending: d.pending,
        recurring: d.recurring
      } ;
      console.log("load_User: CARD COUNT LIST: " + this.theUser.cclist.length);
      window.localStorage.setItem("currentUser", JSON.stringify(this.theUser));    
      callback(d);

    });
  }
 
  update_User(recordID,record){
    this.firestore.doc('users/' + recordID).update(record);
  }
 
  delete_User(record_id) {
    this.firestore.doc('users/' + record_id).delete();
  }

  set_User_parish(userid, client, parishObj){
    this.theUser.parishDetails = parishObj;
    this.theUser.clientId = client;
    this.updateLocal();
    var userRef = this.firestore.collection("users").doc(userid);
      return userRef.update({
        parishDetails: parishObj
        //,clientId: client
      });
  }
  
  getExpiryDateFromCardList(cardToken, cardList){
    cardList.forEach(element => {
      if (element.token == cardToken){
        // we have a match
        return element.card_expiry;
      }
    });
    return null;
  }

  set_User_defaultCCToken(userid, cctoken) {

    return new Promise((resolve, reject) => {
      try
      {
          this.theUser.selectedCC = cctoken;
          this.updateLocal();
          var userRef = this.firestore.collection("users").doc(userid);

          // if recurring data exists.. 
          userRef.get().subscribe(ref => {
            let myData = ref.data();
            if (myData){
              let recID = myData.recurring.sqlId;
              if (this.authToken)
              {          
                let data = {
                  ID: recID,
                  userID: userid,
                  newCC: cctoken
                }
                let url= this.SERVER_URL + "v1/transactions/recurring/updateCC";              
                
                this.http.post(url, data, {headers: new HttpHeaders({
                  'Content-Type':  'application/json',
                  'Authorization': this.authToken
                })
                }).subscribe(res => {           
                  console.log(res) ;
                    userRef.update({
                      selectedCC: cctoken,
                      selectedExpiry: this.getExpiryDateFromCardList(cctoken, myData.cclist)
                    }).then(x => {
                       resolve(true);
                    }).catch(y => {
                       reject("saved in database, but not in app (firestore) " + y);
                    })                                    
                });
              } else {
                alert("authorised token missing - are you logged in? Please login and try again.");
                reject(
                  "authorised token missing - are you logged in? Please login and try again."
                )

              }
            }
          })
        }catch (ex) {
          reject("failed up update default credit card:" + ex);
        }
    });
        
    //    this.selectedCC = cctoken;    
    
  }

  delete_CC(userid, ccObj, callback) {
    var userRef = this.firestore.collection("users").doc(userid);
    return userRef.update({
      cclist: firebase.firestore.FieldValue.arrayRemove(ccObj)      
    }).then((x) => {
      var docRef = this.firestore.collection("users").doc(userid);
      docRef.ref.get().then(doc => {
        let d = doc.data();  
        this.theUser = {
          username: "",
          password: "",
          name: d.name,
          surname: d.surname,
          number: d.number,
          address: d.address,
          parish: d.parish,
          parishDetails: d.parishDetails,
          email: d.email,
          clientId: d.diocese, 
          selectedCC: d.selectedCC,
          selectedExpiry: d.selectedExpiry ? d.selectedExpiry:"",
          cclist: d.cclist,
          transactions: d.transactions,
          pending: d.pending,
          recurring: d.recurring
        } ;
        window.localStorage.setItem("currentUser", JSON.stringify(this.theUser));            
        callback(d.cclist);
    });
    });
  }

  getUserTransactionsAPI(limit, token,callback){
    //let data = {"ID":824,"DioceseID":"100014022","DioceseValue":10,"recID":null,"ParishID":"100000163","ParishValue":4,"ThirdID":"100013525","ThirdValue":0,"GatewayTransID":"8388-P-G58S0DHT","Fee":0,"GatewayTransDate":"2020-02-26T13:30:00.000Z","GatewayTransTime":"15:20:22","userID":"FiAr65LcR9gx4E1waoDYwv4577v1","responseCode":"00","email":"ginilfernandez@gmail.com","ClientID":5}
    //this.setRecurring(data, token, (bla) => {
      //console.log("returned from set recurring" + bla); 

      let re = null;
      let url= this.SERVER_URL + "v1/transactions/" + limit;

      console.log("Getting user transactions usring the URL " + this.SERVER_URL);
      //let header = this.getHeaders(token);
      this.http.get(url, {
        headers: new HttpHeaders(
              {
                'Content-Type':  'application/json',
                'Authorization': token
              }
      )
      }).subscribe(res => {            
        callback(res);      
      });

    //})    
  }

  getHeaders(token){
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': token
      })
    };
  }

  getUserTransactions(limit, callback) {
    let cloudFn = firebase.functions().httpsCallable('getTransactionsForUser');

    let dataToSend = {
      "limit":5
    }
    cloudFn(dataToSend).then((result) => { 
      console.log("got back user transactions ", result);
      callback(result);
    });
  }


  hasRecurring(uid, callback) {
    this.load_User(uid, (x) => {
      let has = true;
      if (x.recurring == "" || x.recurring == null){
        has = false;
      }
      callback(has);
      
    })
  }

  setRecurring(data, callback) {
    
    //let datax = {"ID":824,"DioceseID":"100014022","DioceseValue":10,"recID":null,"ParishID":"100000163","ParishValue":4,"ThirdID":"100013525","ThirdValue":0,"GatewayTransID":"8388-P-G58S0DHT","Fee":0,"GatewayTransDate":"2020-02-26T13:30:00.000Z","GatewayTransTime":"15:20:22","userID":"FiAr65LcR9gx4E1waoDYwv4577v1","responseCode":"00","email":"ginilfernandez@gmail.com","ClientID":5}
    if (this.authToken)
    {          
      let re = null;
      let url= this.SERVER_URL + "v1/transactions/recurring/";
      console.log("userService.setRecurring called with " + JSON.stringify(data));

      //let header = this.getHeaders(token);
      this.http.post(url, data, {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.authToken
      })
      }).subscribe(res => {           
        console.log(res) ;
        callback(res);      
      });
    } else {
      alert("authorised token missing - are you logged in? Please login and try again.");
    }
  }

  deleteRecurringOnServer(uid, callback) {
    if (this.authToken)
    {          
      let re = null;
      let url= this.SERVER_URL + "v1/transactions/recurring/";
      
      //let header = this.getHeaders(token);
      let data = { user_ID: uid };
      console.log("userService.setRecurring called with " + JSON.stringify(data));
      this.http.put(url, data, {headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': this.authToken
      })
      }).subscribe(res => {           
        console.log(res) ;
        callback(res);      
      });
    } else {
      alert("authorised token missing - are you logged in? Please login and try again.");
    }
  }
    

}