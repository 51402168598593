import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'third-collection',
  templateUrl: './third-collection.component.html',
  styleUrls: ['./third-collection.component.scss'],
})
export class ThirdCollectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
