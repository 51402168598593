import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'date-calendar-icon',
  templateUrl: './date-calendar-icon.component.html',
  styleUrls: ['./date-calendar-icon.component.scss'],
})
export class DateCalendarIconComponent implements OnInit {

  dayOfWeek: string; 
  month: string;
  date: number;

  months:any = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
  @Input() dateString: string;
  constructor() { }

  ngOnInit() {
    let date= new Date(this.dateString);
    this.dayOfWeek = this.days[date.getDay()].substr(0,3);
    this.month = this.months[date.getMonth()].substr(0,3);
    this.date = date.getDate();
  }

}
