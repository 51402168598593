import { FindMassService } from './../../providers/find-mass.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'worship-time',
  templateUrl: './worship-time.component.html',
  styleUrls: ['./worship-time.component.scss'],
})
export class WorshipTimeComponent implements OnInit {
  @Input() churchName:string;
  @Input() distance: number;
  @Input() time: string;
  @Input() times: any;
  @Input() notes: string;
  @Input() churchID: string;
  datex: Date;
  datesx: Date[] = new Array();
  timeStrings: string[] = new Array();
  constructor(findMass: FindMassService ) { 
    
  }  

  formatTimeShow(h_24, minutes) {
    var h = h_24 % 12;
    if (h === 0) h = 12;
    // return (h < 10 ? '0' : '') + h + ':' + minutes + (h_24 < 12 ? ' am' : ' pm');
    return  h + ':' + minutes + (h_24 < 12 ? ' am' : ' pm');
  }

  minutes_with_leading_zeros(min) 
  { 
    return (min < 10 ? '0' : '') + min;
  }

  convertToDate(str)
  {
    let d = new Date();
    let s = new String(str);

    let hrs =  s.substr(0,2);
    let min = s.substr(3,2);
    d.setHours(parseInt(hrs));
    d.setMinutes(parseInt(min));
   
    return d;
  }

  getTimeString(s){
    let hrs =  s.substr(0,2);
    let min = s.substr(3,2);
    let str = this.formatTimeShow(hrs, min);
    return str;
  }

  ngOnInit() {
    this.times.forEach(element => {          
      this.timeStrings.push(this.getTimeString(element.time));      
    });
        
  }



}
