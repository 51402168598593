import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'first-collection',
  templateUrl: './first-collection.component.html',
  styleUrls: ['./first-collection.component.scss'],
})
export class FirstCollectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
