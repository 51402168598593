import { LoginPage } from './../../pages/login/login';
import { UserFB } from './../../interfaces/user-options';
import { Component, OnInit, Input, EventEmitter, Output, NgZone } from '@angular/core';
import { UserService } from '../../providers/user.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'add-card',
  templateUrl: './add-card.component.html',
  styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {
  loggedIn: number;
  theUser:UserFB;
  emailVerified:boolean = false;
  @Input() showAddCard: boolean = true;
  @Output() addSuccess = new EventEmitter<string>();  
  showCardFrame:boolean = true;
  cardURL:string="https://paynow-sandbox.pmnts.io/v2/TESTdexterity/ABC123/AUD/10.00/6119a6df1db4209011f6ed2e7c5b35d1?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&layout=v2&return_target=_self&logo_url=https://dxts.com.au/images/banner_adelaide.png&postmessage=true"
  cartURLProd:string="https://paynow-sandbox.pmnts.io/v2/TESTdexterity/ABC123/AUD/10.00/6119a6df1db4209011f6ed2e7c5b35d1?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&layout=v2&return_target=_self&logo_url=https://dxts.com.au/images/banner_adelaide.png&postmessage=true"

  constructor(
    public afAuth: AngularFireAuth,
    public userService: UserService,
    public ngZone: NgZone
  ) { 

    if (environment.production) {
      this.cardURL = this.cartURLProd;
    }
    
    this.loggedIn = 0; 
    this.afAuth.authState.subscribe(user => this.ngZone.runOutsideAngular(()=> {
     
      if (this.afAuth.auth.currentUser == null){
        this.loggedIn = 1;    
        // current user in null... reset firebaseui.. just incase.
        
      }
      else 
      {        
        this.loggedIn = 2;
        
        this.loadUserDetails(this.afAuth.auth.currentUser.uid);     
        let token = userService.get_User_selectedCC();
        if (token) {
          this.showAddCard = false; /// already have 1 card.. so need to add card. 
          this.addSuccess.emit(token);
        }                
      }
    
    }));
  };
 
  cardAdded(token) {  
    this.addSuccess.emit(token);
  }

  cardListChanged(list) {
    if (list.lenght == 0) {
      this.showCardFrame = true;
    }
    else 
    {
      this.showCardFrame = false;
    }
  }
  loadUserDetails(userid) {
    this.userService.load_User(userid,(d) => {        
      this.theUser = d; 
      if (this.theUser.cclist.length == 0) {
        this.showCardFrame = true;
      }
      console.log("@@@@@@@@@@@@@@@@updated user details to loca " + d);
    })
  }

  ngOnInit() {}

}
