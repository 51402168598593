// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  SERVER:
    "https://australia-southeast1-d2u-all-dev.cloudfunctions.net/mass-api/", // SERVER:"http://localhost:8020/",
  firebase: {
    apiKey: "AIzaSyDz7qUHDz9gOc5x7_Wc1X2brtKT27thY_E",
    authDomain: "d2u-admin-dev.firebaseapp.com",
    databaseURL: "https://d2u-all-dev.firebaseio.com",
    projectId: "d2u-all-dev",
    storageBucket: "d2u-all-dev.appspot.com",
    messagingSenderId: "705319615924",
    appId: "1:705319615924:web:746ad964c329dbba5b46e1",
    measurementId: "G-L2NHQ6T4XC"
  },
  FILE_LIMIT_SIZE: 2,
  COUNT: 10
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
