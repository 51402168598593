import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'flip-loading',
  templateUrl: './flip-loading.component.html',
  styleUrls: ['./flip-loading.component.scss'],
})
export class FlipLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
