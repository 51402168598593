import { AdminUserService } from './../../providers/admin-user.service';
import { Component, OnInit } from '@angular/core';
import { ReportingServiceService } from '../../providers/reporting-service.service';

@Component({
  selector: 'summary-report',
  templateUrl: './summary-report.component.html',
  styleUrls: ['./summary-report.component.scss'],
})
export class SummaryReportComponent implements OnInit {
  summaryData: any = "No data recieved"
  constructor(public reporting:ReportingServiceService, public admin:AdminUserService) { }

  ngOnInit() {
    this.reporting.getSummaryReport(this.admin.getClientID(), this.admin.getAuthToken(), (x)=>{      
      this.summaryData = JSON.stringify(x);
    })
  }

}
