import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';


interface NewsFeed {
  id: number;
  name: string;
}

interface NewsType {
  text: string;
  value: string;
}


@Component({
  selector: 'app-create-post',
  templateUrl: './create-post.component.html',
  styleUrls: ['./create-post.component.scss'],
})
export class CreatePostComponent implements OnInit {
  title: string = "Add Event";
  eventsCollection = 'events';
  newsFeedCollection = 'newsFeed';
  imgUrl = '';
  eventForm: FormGroup;
  newsFeeds: NewsFeed[];
  newsType: NewsType[] = [
    {
      text: 'News',
      value: 'news',
    },
    {
      text: 'Prayer',
      value: 'prayer',
    },
    {
      text: 'Homily',
      value: 'homily',
    },
    {
      text: 'Event',
      value: 'event',
    },
  ];

  showEvent:boolean = false;

  constructor(
    public formBuilder: FormBuilder
  ) { }

  ngOnInit() {
    this.eventForm = this.formBuilder.group({
      newsFeedID: '',
      name: '',
      description: '',
      image: '',
      startDateTime: '',
      endDateTime: '',
      recurring: '',
      endDate: '',
      nextDate: '',
      url: '',
      urlText: '',
      type: ''
    });
  }

  onSubmit() {

  }

  upload($event){

  }

  isEvent(){
    this.showEvent = true;
  }

  isNews(){
    this.showEvent = false;
  }
}
