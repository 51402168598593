import { Component, OnInit, Input } from '@angular/core';


@Component({
  selector: 'summary-recurring',
  templateUrl: './summary-recurring.component.html',
  styleUrls: ['./summary-recurring.component.scss'],
})
export class SummaryRecurringComponent implements OnInit {
  //@Input() items:any = {list:[{"text":"loading..","value":"$77"},{"text":"loading2..","value":"$778"}]}; 
  @Input() total:any = {text:"Total", value:"-"};
  @Input() lineitem: any = [];
  @Input() recurring: string = "one-off donation";
  
  constructor() { }

  ngOnInit() {
    let bla = 2;
    
    //alert(this.lineitem);
  }

  ngOnChanges(data: any){
    //console.log("updated summary recurring component " + JSON.stringify( data) );
  }

}
