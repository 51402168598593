import { CustomerService } from './../../providers/customer.service';
import { AlertController } from '@ionic/angular';
import { UserService } from './../../providers/user.service';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserFB } from '../../interfaces/user-options';

@Component({
  selector: 'list-cards',
  templateUrl: './list-cards.component.html',
  styleUrls: ['./list-cards.component.scss'],
})
export class ListCardsComponent implements OnInit {
  showListCards: boolean = false;
  @Input() showAddCard: false;
  @Input() hasRecurring: any = null; 
  theUser:UserFB;
  userService: UserService;
  updatingDefault = false;
  @Output() cardListChanged = new EventEmitter<number>();

  constructor(userServices: UserService,public customer:CustomerService, public alertController: AlertController) { 
    this.userService = userServices;
    
    
    
    if (this.customer.customerSelected())
    {
      this.userService.load_User(this.customer.getCustomer(),(d) => {        
        this.theUser = d; 
        this.showListCards = true;
        console.log("cons CARD LIST IS: " + this.theUser.cclist.length)
       // debugger;
      })      
    }
    else 
    {
      this.showListCards = false;
    }
  }

  ionViewDidEnter(){
    
    if (this.customer.getCustomer())
    {
      this.userService.load_User(this.customer.getCustomer(),(d) => {        
        this.theUser = d; 
        this.showListCards = true;
        console.log("ionViewDidEnter CARD LIST IS: " + this.theUser.cclist.length)
       // debugger;
      })      
    }
    else 
    {
      this.showListCards = false;
    }
  }
  async presentAlert() {

    const alert = await this.alertController.create(
      {
        header: 'Cannot delete payment method',
        subHeader: 'Reason',
        message: 'You currently have a recurring donation setup on this payment method. You can cancel the recurring payment method OR set a new DEFAULT payment method before deleting this one.',
        buttons: [
         {
           text: 'OK'           
         }         
        ]
      }
    );

    await alert.present();
  }

  async presentConfirm() {
    
    
    
  }

  async deleteCC(cardObj){
    

        if (cardObj.token == this.theUser.selectedCC)
        {
          if (this.hasRecurring == null) {
            // is DEFAULT card, and there is no recurring payment
            // deleteing a default card. 
            this.theUser.selectedCC = "";
          }
          else
          {
            // is DEFAULT card, and HAS a recurring payment
            this.presentAlert().then( (x) => {
              return;
            });
            return;
            
          }
          
        }

        const alert = await this.alertController.create(
          {
            header: 'Confirm',
            subHeader: 'Are you sure you want to delete the payment method? ',
            //message: 'This cannot be undone. All recurring transactions from tomorrow onwards will be cancelled',
            buttons: [
             {
              text: 'No', 
               handler: () => {
                  return;
              }
             },
             {
               text: 'Yes', 
               handler: () => {
                  this.presentConfirm().then( (val) => {
                    
                    // NOT Default card - so can delete, even if a recurring payment. recurring payment will use default card. 
                    console.log("deleting credit card", cardObj);
                    this.userService.delete_CC(this.customer.getCustomer(), cardObj,(d) => {
                      
                        this.theUser.cclist = d;
                        if (d[0])
                        {
                          this.theUser.selectedCC = d[0].token;
                        }
                        else 
                        {
                          this.theUser.selectedCC == "";
                        }
                        this.changeSelectedCC(this.theUser.selectedCC);
                        this.cardListChanged.emit(this.theUser.cclist.length);
                        
                    });  
                  });            
               }
             }
            ]
          }
        );
    
        await alert.present();
        
  }

  changeSelectedCC(newToken) {
     this.updatingDefault = true;
      this.userService.set_User_defaultCCToken(this.customer.getCustomer(), newToken).then((d) => {
          console.log("@@@@@@@@@@@@@@@" + d);
          this.theUser.selectedCC = newToken;
          this.updatingDefault = false;
        });
  }

 

  ngOnInit() {}

}
