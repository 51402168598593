import { DataServiceService } from './../../providers/data-service.service';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'psalm-loader',
  templateUrl: './psalm-loader.component.html',
  styleUrls: ['./psalm-loader.component.scss'],
})
export class PsalmLoaderComponent implements OnInit {
  @Input() showLoader: boolean;
  @Input() textInput: string;
  myText: string = "loading..";
  dataService: any;
  myPsalm: any = {
    "psalm":"loading..", "ref":""
  }
  constructor(ds: DataServiceService) {
    this.dataService = ds;
    
  }

  ngOnInit() {
    this.myPsalm = this.dataService.getRandomPsalm();
    this.myText = this.textInput;
    //console.log("random psalm is: " + JSON.stringify(this.myPsalm));
  }

}
