import { AngularFirestore } from '@angular/fire/firestore';
import { UtilitiesService } from './../../providers/utilities.service';
import { AdminUserService } from './../../providers/admin-user.service';
import { FirestoreService } from './../../providers/firestore.service';
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { nullSafeIsEquivalent } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-parish-info',
  templateUrl: './parish-info.component.html',
  styleUrls: ['./parish-info.component.scss'],
})
export class ParishInfoComponent implements OnInit {
  parishID: string = "";
  // formBuilder:FormBuilder;

  // editing parihs details  
  showForm:boolean = true;
  parishDetailsForm: FormGroup;
  isSubmitted:boolean = false;
  parishName:string=""
  parishDetailsObj = {
    address:"",
    postalAddress:"",
    phone:"",
    fax:"",
    email:"",
    image:"",
    officeHours:""
  }
  imgUrl:string= "";
  COLLECTION_PARISH_INFO:string="parishInfo";
  loaded:boolean =false;

  constructor(public formBuilder:FormBuilder, 
              //public firestoreService:FirestoreService, 
              public firestore:AngularFirestore,
              public admin:AdminUserService,
                    public utilityService:UtilitiesService) { }

  ngOnInit() {
    this.parishID = this.admin.getSelectedParishID();
    debugger;
    this.parishDetailsForm = this.formBuilder.group({      
      address: [''],
      postalAddress: [''],
      phone: [''],
      fax: [''],
      email: ['' ,[Validators.email ]],
      image: [''],
      officeHours: ['']
    });
    //this.firestoreService.getItem(this.COLLECTION_PARISH_INFO, this.parishID).subscribe((res: any) => {
      this.firestore.collection(this.COLLECTION_PARISH_INFO).doc(this.parishID).get().subscribe((resd: any) => {
        let res = resd.data();
      this.parishDetailsForm.patchValue({
        address: res.address || "",
        postalAddress: res.postalAddress || "",
        phone: res.phone || "",
        fax: res.fax || "",
        email: res.email || "",
        image: res.image || "",
        officeHours: res.officeHours || ""
      });      
      this.parishDetailsForm.value['image'] = res.imageURL;
      this.imgUrl = res.imageURL;
      this.parishName = res.parish;
      this.loaded = true;
    })
  }

  get errorControl() {
    return this.parishDetailsForm.controls;
  }

  onSubmit() {
    this.isSubmitted = true;
    if (!this.parishDetailsForm.valid) {
      return false;
    } else {
      this.parishDetailsForm.value['imageURL'] = this.imgUrl;
      this.parishDetailsForm.value['updatedBy'] = this.admin.afAuth.auth.currentUser.uid;
      this.parishDetailsForm.value['updatedByEmail'] = this.admin.afAuth.auth.currentUser.email;
      this.parishDetailsForm.value['updated'] = new Date();
      this.parishDetailsForm.value['deleted'] = false;
      
      this.isSubmitted = false;
      //this.firestoreService.updatePromise(this.COLLECTION_PARISH_INFO, this.parishID, this.parishDetailsForm.value)
      this.firestore.collection(this.COLLECTION_PARISH_INFO).doc(this.parishID).set(this.parishDetailsForm.value, {merge:true})
        .then((res) => {
          console.log('updated parish' , this.parishDetailsForm.value);
          this.utilityService.presentToast('Parish updated  successfully!');
          //this.parishDetailsForm.reset();
          this.isSubmitted = false;
        }).catch((err) => {
          console.log('parish update err +++', err);
          this.utilityService.presentToast('Something went wrong, tyr again later!');
        });
    }
  }
}
