import { ToastController } from '@ionic/angular';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-button-config',
  templateUrl: './app-button-config.component.html',
  styleUrls: ['./app-button-config.component.scss'],
})
export class AppButtonConfigComponent implements OnInit {
  radioSelection:string = "filter";
  showExtra:boolean = true;
  showSaveButton:boolean = false;
  @Input() configProperties:any = {
    type:1,
		name: "News",
		showHide: true,
		useAsFilter: true,
		url: "https://google.com"

  }; // determine which parish list to get.
  urlInput:string = "";
  showURL:boolean = false;
  type:number = 1;  
  MAKE_BUTTON_URL:string= "Make button URL";
  MAKE_BUTTON_FILTER:string="Make button filter"
  makeButtonText:string = this.MAKE_BUTTON_URL;
  @Output() propertyChanged = new EventEmitter<object>();

  constructor(private toastController:ToastController) { }

  ngOnInit() {
    // console.log("ngInit" + JSON.stringify(this.configProperties));  
    // if (this.configProperties.useAsFilter == true){
    //   this.makeFilter();
    // }
    // else
    // {
    //   this.makeURL();
    // }
    
  }

  ngOnChanges(changes: any){
    if (this.configProperties.useAsFilter == true){
      this.radioSelection = "filter";
    }
    else
    {
      this.radioSelection = "url";
    }
  }

  // Below are the three properties that can change. 
  radioSelected($event){
    
    if (this.radioSelection == "url"){
      this.configProperties.useAsFilter = false;
    }
    else {
      this.configProperties.useAsFilter = true;
    }
    //alert(this.configProperties.useAsFilter)
    this.propertyChanged.emit(this.configProperties);
  }

  saveURL(){   
     
    this.saveMode()
  }

  segmentChanged($event){
    console.log("SEGMENT CHANGED:" + JSON.stringify($event) + this.configProperties.useAsFilter );
  }

  showHideChanged() {
    // only save, if turned off. 
    this.propertyChanged.emit(this.configProperties);

    // if (this.configProperties.showHide == false){
    //   this.propertyChanged.emit(this.configProperties);
    // }
    // else
    // {
    //   if (this.type == 1){
    //     this.presentToastInfo("Please enter valid URL and hit save to preserve change");
    //   }
    //   else if (this.type == 2){
    //     this.configProperties.urlString="";
    //     this.propertyChanged.emit(this.configProperties);  
    //   }
    // }
   
  }

  // makeButtonURL($event) {
  //   this.configProperties.useAsFilter = !this.configProperties.useAsFilter;
  //   console.log("Changed to/Saving UseAsFilter" + this.configProperties.name + " " + this.configProperties.useAsFilter );
  //   if (this.configProperties.useAsFilter == false) {
  //     this.showURL = true;
  //   }
  //   else 
  //   {
  //     this.showURL = false;
  //   }
  //   //this.propertyChanged.emit(this.configProperties);
  // }

  // toggleButtonURL() {
  //   if (this.makeButtonText == this.MAKE_BUTTON_FILTER)
  //   {
  //     this.showURL = false;
  //     this.configProperties.useAsFilter = true;
  //     this.makeButtonText = this.MAKE_BUTTON_URL;
  //   }
  //   else if (this.makeButtonText == this.MAKE_BUTTON_URL)
  //   {
  //     this.showURL = true;
  //     this.configProperties.useAsFilter = false;
  //     this.makeButtonText = this.MAKE_BUTTON_FILTER;      
  //   }
  //   this.propertyChanged.emit(this.configProperties);
  // }

  makeFilter() {
    this.showURL = false;
    this.configProperties.useAsFilter = true;
    this.propertyChanged.emit(this.configProperties);
  }

  makeURL() {
    this.showURL = true;
    this.configProperties.useAsFilter = false;
    this.propertyChanged.emit(this.configProperties);
  }

  saveMode() {    
    if (this.isValidHttpUrl()) {
      this.propertyChanged.emit(this.configProperties);
    }
    else
    {
      this.presentToast("Cannot save without valid URL");
    }
  }

  urlChanged() {
    this.propertyChanged.emit(this.configProperties);
  }

  isValidHttpUrl() {
    let url;
    
    try {
      url = new URL(this.configProperties.urlString);
    } catch (_) {
      return false;  
    }
  
    return url.protocol === "http:" || url.protocol === "https:";
  }

  async presentToast(mes) {
    const toast = await this.toastController.create({
      message: mes,
      duration: 2000,
      color: "danger"
    });
    toast.present();
  }

  async presentToastInfo(mes) {
    const toast = await this.toastController.create({
      message: mes,
      duration: 2000,
      color:'secondary'
    });
    toast.present();
  }
  
  
}
