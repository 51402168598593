import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'parish-collection',
  templateUrl: './parish-collection.component.html',
  styleUrls: ['./parish-collection.component.scss'],
})
export class ParishCollectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
