import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'keypad',
  templateUrl: './keypad.component.html',
  styleUrls: ['./keypad.component.scss'],
})
export class KeypadComponent implements OnInit {
  value: string  = "";
  maskedValue: string = "";
  isMasked: boolean = false;
  constructor() { }

  num(num) {
    this.value = this.value + num.toString();
    this.maskedValue = this.maskedValue + "*";
  };

  backPressed() {
    this.value = this.value.substring(0,this.value.length -1);
    this.maskedValue = this.maskedValue.substring(0,this.value.length -1)
  }

  clear() {
    this.value = "";
    this.maskedValue = "";
  }
  ngOnInit() {}

}
