import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';

@Component({
  selector: 'third-selector',
  templateUrl: './third-selector.component.html',
  styleUrls: ['./third-selector.component.scss'],
})
export class ThirdSelectorComponent implements OnInit {
  @Input() diocese:number; // determine which parish list to get.
  @Output() selected = new EventEmitter<number>();
  selectedThirdCollectionId: number = -1;

  thirdcollections: any[] = [
    {
      id: 1,
      first: 'St Vincent De Paul',
      last: 'Homless',
    },
    {
      id: 2,
      first: 'Seafarers',
      last: 'People form the sea',
    },
    {
      id: 3,
      first: 'Catholic Mission',
      last: 'Various projects like water/aids',
    }
  ];

  
  

  constructor() { }

  selectThird(event){
    console.log(event);
      this.selectedThirdCollectionId = event.detail.id;
      this.selected.emit(this.selectedThirdCollectionId);
  };

  ngOnInit() {}

}
