import { ParishInfoComponent } from './parish-info/parish-info.component';
import { AdminEditComponent } from './admin-edit/admin-edit.component';
import { CreatePostComponent } from './create-post/create-post.component';

import { FileSizeFormatPipe } from './file-upload/file-size-pipe';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { SummaryReportComponent } from './summary-report/summary-report.component';
import { GivingDateRangeComponent } from './giving-date-range/giving-date-range.component';
import { TransactionSummaryComponent } from './transaction-summary/transaction-summary.component';
import { SecureLoaderComponent } from './svg/secure-loader/secure-loader.component';
import { AbnComponent } from './abn/abn.component';
import { PsalmLoaderComponent } from './psalm-loader/psalm-loader.component';
import { DayTimesComponent } from './day-times/day-times.component';

import { ChurchDetailsTimesComponent } from "./church-details-times/church-details-times.component";
import { ChurchDetailsHeaderComponent } from "./church-details-header/church-details-header.component";
import { WorshipTimeComponent } from "./worship-time/worship-time.component";
import { FlipLoadingComponent } from "./svg/flip-loading/flip-loading.component";
import { SecondCollectionComponent } from "./second-collection/second-collection.component";
import { FirstCollectionComponent } from "./first-collection/first-collection.component";
import { DioceseCollectionComponent } from "./diocese-collection/diocese-collection.component";
import { SummaryRecurringComponent } from "./summary-recurring/summary-recurring.component";
import { ListCardsComponent } from "./list-cards/list-cards.component";
import { SaveCardOnlyComponent } from "./save-card-only/save-card-only.component";
import { FirebaseUIModule } from "firebaseui-angular";
import { AddCardComponent } from "./add-card/add-card.component";
import { KeypadComponent } from "./keypad/keypad.component";
import { ThirdSelectorComponent } from "./third-selector/third-selector.component";
import { ParishSelectorComponent } from "./parish-selector/parish-selector.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { BrowserModule } from "@angular/platform-browser";
import { StatusBoxComponent } from "./status-box/status-box.component";
import { DonationValueComponent } from "./donation-value/donation-value.component";
import { IonicModule } from "@ionic/angular";
import { RecurringOptionsComponent } from "./recurring-options/recurring-options.component";
import { NgModule, NgZone } from "@angular/core";

import { ParishCollectionComponent } from "./parish-collection/parish-collection.component";
import { ThirdCollectionComponent } from "./third-collection/third-collection.component";
import { IonicSelectableModule } from "ionic-selectable";
import { DateCalendarIconComponent } from "./date-calendar-icon/date-calendar-icon.component";
import { NetworkCheckComponent } from "./network-check/network-check.component";
import { DatePickerModule } from "ionic4-date-picker";
import { ChurchSelectorComponent } from "./church-selector/church-selector.component";
import { AppButtonConfigComponent } from "./app-button-config/app-button-config.component";

@NgModule({
  declarations: [
    RecurringOptionsComponent,
    DonationValueComponent,
    StatusBoxComponent,
    ParishSelectorComponent,
    ThirdSelectorComponent,
    KeypadComponent,
    AddCardComponent,
    SaveCardOnlyComponent,
    ListCardsComponent,
    SummaryRecurringComponent,
    ParishCollectionComponent,
    DioceseCollectionComponent,
    ThirdCollectionComponent,
    FirstCollectionComponent,
    SecondCollectionComponent,
    TransactionSummaryComponent,
    FlipLoadingComponent,
    SecureLoaderComponent,
    WorshipTimeComponent,
    ChurchDetailsHeaderComponent,
    ChurchDetailsTimesComponent,
    DayTimesComponent,
    FileUploadComponent,
    DateCalendarIconComponent,
    PsalmLoaderComponent,
    AbnComponent,
    NetworkCheckComponent,
    GivingDateRangeComponent,
    SummaryReportComponent,
    FileSizeFormatPipe,
    AppButtonConfigComponent,
    ChurchSelectorComponent,
    CreatePostComponent,
    AdminEditComponent,
    ParishInfoComponent
  ],
  imports: [
    IonicModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    FirebaseUIModule,
    IonicSelectableModule,
    DatePickerModule,
  ],
  exports: [
    RecurringOptionsComponent,
    DonationValueComponent,
    StatusBoxComponent,
    ParishSelectorComponent,
    ThirdSelectorComponent,
    KeypadComponent,
    AddCardComponent,
    SaveCardOnlyComponent,
    ListCardsComponent,
    SummaryRecurringComponent,
    ParishCollectionComponent,
    DioceseCollectionComponent,
    ThirdCollectionComponent,
    FirstCollectionComponent,
    SecondCollectionComponent,
    TransactionSummaryComponent,
    FlipLoadingComponent,
    SecureLoaderComponent,
    WorshipTimeComponent,
    ChurchDetailsHeaderComponent,
    ChurchDetailsTimesComponent,
    DayTimesComponent,
    DateCalendarIconComponent,
    PsalmLoaderComponent,
    AbnComponent,
    NetworkCheckComponent,
    GivingDateRangeComponent,
    SummaryReportComponent,
    FileUploadComponent,
    ChurchSelectorComponent,
    AppButtonConfigComponent,
    CreatePostComponent,
    AdminEditComponent,
    ParishInfoComponent
  ],
})
export class SharedModule {}
