import { KeypadComponent } from './../keypad/keypad.component';
import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { PopoverController } from '@ionic/angular';

@Component({
  selector: 'donation-value',
  templateUrl: './donation-value.component.html',
  styleUrls: ['./donation-value.component.scss'],
})
export class DonationValueComponent implements OnInit {
  @Input() donationValue:number = 0;
  @Input() title:string = "Title";
  @Input() subtitle:string = "";
  @Input() svgPath = "";
  @Output() valueSet = new EventEmitter<number>();
  
  constructor(public popoverController: PopoverController) { }

  async presentPopover(ev: any) {
    const popover = await this.popoverController.create({
      component: KeypadComponent,
      event: ev,
      translucent: false
    });
    return await popover.present();
  }

  ngOnInit() {}

  outputValue(){
    if (this.donationValue < 0) {
      this.donationValue = 0;
    }
    this.valueSet.emit(this.donationValue);
  }

  setValue(val){
    this.donationValue=val;
    this.outputValue();
  }

  inc(){
    this.donationValue++;
    this.outputValue();
  }

  dec() {
    this.donationValue--;
    if (this.donationValue < 0){
      this.donationValue = 0;
    }
    this.outputValue();
  }
}
