import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'status-box',
  templateUrl: './status-box.component.html',
  styleUrls: ['./status-box.component.scss'],
})
export class StatusBoxComponent implements OnInit {
  @Input() statusTitle:string = "Parish";
  @Input() statusText:string = "$25";
  @Input() status:boolean = false ; // 
  
  constructor() { }

  ngOnInit() {}

}
