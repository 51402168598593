import { AdminUserService } from "./admin-user.service";
import { Injectable, Inject } from "@angular/core";
import {
  HttpClientJsonpModule,
  HttpClientModule,
  HttpClient,
  HttpHeaders,
} from "@angular/common/http";
import { AngularFirestore } from "@angular/fire/firestore";
import { environment } from "../../environments/environment";
import * as moment from "moment";
import { rrulestr } from "rrule";
// import * as ClientDataJSON from './clients.json';
import { CLIENT_RENEG_WINDOW, CLIENT_RENEG_LIMIT } from "tls";
import { ParishData } from "./ParishLookup.js";

@Injectable({
  providedIn: "root",
})
export class DataServiceService {
  AllParishData: any = ParishData;
  ccAppeals: any = [];
  otherEntities: any = [];
  otherEntityCodeLookup: any = {};
  otherEntityIDLookup: any = {};
  //otherEntityData:any = {};
  otherEntityList: any = [];
  parishListData: any = {};
  parishLookup: any = {};
  parishCodeLookup: any = {};

  res: any;
  SERVER_URL = environment.SERVER;
  WORSHIP_SERVER_URL = this.SERVER_URL.replace("mass-api","worship-api");

  psalms: any = [
    { psalm: "The Lord is my strength and my song", ref: "Exodus 15:2" },
    {
      psalm:
        "Seek first his kingdom and his righteousness, and all these things will be given to you",
      ref: "Matthew 6:33",
    },
    {
      psalm: "Those who hope in the Lord will renew their strength.",
      ref: "Isaiah 40:31",
    },
    { psalm: "Do everything in love", ref: "1 Corinthians 16:13-14" },
    {
      psalm:
        "May the God of hope fill you with all joy and peace as you trust in him",
      ref: "Romans 15:13",
    },
    {
      psalm:
        "And now these three remain: faith, hope and love. But the greatest of these is love",
      ref: "1 Corinthians 13:13",
    },
    { psalm: "Be still, and know that I am God", ref: "Psalm 46:10" },
    { psalm: "The spirit of the Lord is upon me", ref: "Isaiah 61:1 " },
    { psalm: "You are the light of the world", ref: "Matthew 5:14 " },
    {
      psalm:
        "With man this is impossible, but not with God; all things are possible with God",
      ref: "Mark 10:27 ",
    },
  ];
  fullParishList: any = [];
  CLIENT_ID: number = 0;
  specialCollectionDetails: any = null;

  ClientData = {
    clientList: [
      {
        title: "Adelaide Archdiocese",
        name: "adelaide",
        id: 5,
        image: "assets/img/logo.adelaide.png",
      },
      {
        title: "Darwin Diocese",
        name: "darwin",
        id: 15,
        image: "assets/img/logo.darwin.png",
      },
      {
        title: "Sydney Archdiocese",
        name: "sydney",
        id: 20,
        image: "assets/img/logo.sydney.png",
      },
      {
        title: "Diocese of Broken Bay",
        name: "brokenbay",
        id: 17,
        image: "assets/img/logo.bb.png",
      },
    ],

    clientDetails: {
      adelaide: {
        title: "Adelaide Archdiocese",
        id: 5,
        image: "assets/img/logo.adelaide.png",
        cardURL: "",
        cardURLPROD: "",
        abn: "296 082 970 12",
        entity: "Catholic Church Endowment Society Inc",
      },
      darwin: {
        title: "Darwin Diocese",
        id: 15,
        image: "assets/img/logo.darwin.png",
        cardURL: "",
        cardURLPROD: "",
        abn: "11 296 082 970",
        entity: "Darwin Catholic Church Fund Inc",
      },
      brokenbay: {
        title: "Diocese of Broken Bay",
        id: 17,
        image: "assets/img/logo.bb.png",
        cardURL: "",
        cardURLPROD: "",
        abn: "33 296 082 970",
        entity: "Sydney Catholic Church Fund Inc",
      },
    },
  };

  private readonly publicHolsNSW = [
    "20190128",
    "20190419",
    "20190420",
    "20190421",
    "20190422",
    "20190425",
    "20190610",
    "20190805",
    "20191007",
    "20191225",
    "20191226",
    "20200101",
    "20200127",
    "20200410",
    "20200411",
    "20200412",
    "20200413",
    "20200425",
    "20200608",
    "20200803",
    "20201005",
    "20201225",
    "20201226",
    "20201228",
    "20210101",
    "20210126",
    "20210402",
    "20210403",
    "20210404",
    "20210405",
    "20210425",
    "20210614",
    "20210802",
    "20211004",
    "20211225",
    "20211226",
    "20211227",
    "20211228",
    "20220101",
    "20220103",
    "20220126",
    "20220415",
    "20220416",
    "20220417",
    "20220418",
    "20220425",
    "20220613",
    "20220801",
    "20221003",
    "20221225",
    "20221226",
    "20221227"
  ];

  constructor(public http:HttpClient, private firestore: AngularFirestore) {
    //@Inject('clientID') private clientID: number) { 

    // console.log("************* CLIENT ID IS " + clientID);
    // this.http.jsonp("https://findmass.azurewebsites.net/api/massdata/12",'callback')
    // .subscribe(res => this.res = res);

    // this.specialCollectionDetails =
    // {
    //   ID: 1,
    //   Title: "Day of the Unborn Child",
    //   Description: "Diocesan Mass for those who die before birth. Collection for Birth line, Right to Life SA, MaterCare Australia & Genesis Pregnancy Support Inc."
    // };
    firestore.firestore.enablePersistence().catch((err) => {
      if (err.code == "failed-precondition") {
        console.log("Failed Persisncent 1");
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == "unimplemented") {
        console.log("Failed Persisncent 2");
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

    this.getParishListFB()
      .then((x) => {
        this.fullParishList = x;
        //console.log("loaded parish list... " + JSON.stringify(x));
        // create lookup for parishes.
        this.fullParishList.map((el) => {
          this.parishLookup[el.ID] = el;
        });
      })
      .catch((y) => {
        console.log("failed to load parish list");
      });

    //this.specialCollectionDetails = null;
    // this.getSpecialCollectionZ(5).then(xxd => {
    //   if (xxd)
    //   {
    //     this.specialCollectionDetails = xxd[0];
    //   }
    //   else
    //   {
    //     this.specialCollectionDetails = null;
    //   }
    //   console.log("special collection is:" + JSON.stringify(this.specialCollectionDetails));
    // });
  }

  clientID: number = 5;
  clientName: string = "adelaide"; // default to adelaide

  async setDioceseString(val) {
    console.log("DataService:setDioceseString called with " + val);
    let obj = this.ClientData.clientDetails[val];
    if (obj) {
      this.clientID = obj.id;
      console.log(
        "DataService:setDioceseString client id set to  " + this.clientID
      );
      this.clientName = val;
      // only get parish list if it's not there already.
      if (this.fullParishList.length == 0) {
        await this.getParishListFB()
          .then((x) => {
            this.fullParishList = x["parishes"];
            this.otherEntities = x["otherEntities"];

            console.log(
              "loaded parish list and other entities for: " + this.clientName
            );
            // create lookup for parishes.
            this.fullParishList.map((el) => {
              this.parishLookup[el.ID] = el;
              this.parishCodeLookup[el.code] = el;
            });

            if (!this.objectIsEmpty(this.otherEntities)) {
              this.otherEntityCodeLookup = {};
              this.otherEntities.map((el) => {
                this.otherEntityCodeLookup[el.code] = el;
                this.otherEntityIDLookup[el.ID] = el;
              });
            }

            // store in local storage;
            localStorage.setItem(
              "parishLookup",
              JSON.stringify(this.parishLookup)
            );
            localStorage.setItem(
              "parishCodeLookup",
              JSON.stringify(this.parishCodeLookup)
            );
            localStorage.setItem(
              "otherEntityIDLookup",
              JSON.stringify(this.otherEntityIDLookup)
            );
            localStorage.setItem(
              "otherEntityCodeLookup",
              JSON.stringify(this.otherEntityCodeLookup)
            );
          })
          .catch((y) => {
            console.log("failed to load parish list");
          });
      }
    }
  }

  setParishString(val) {
    // do nothing for now.
  }

  setClient(clientid, name) {
    if (this.clientID == clientid) {
      return; // do nothing.
    }
    this.clientID = clientid;
    this.clientName = name;
    // load parish data again.
    this.getParishListFB()
      .then((x) => {
        this.fullParishList = x;
        console.log("loaded parish list... ");
        // create lookup for parishes.
        this.fullParishList.map((el) => {
          this.parishLookup[el.ID] = el;
        });
      })
      .catch((y) => {
        console.log("failed to load parish list");
      });
  }

  getClientID() {
    return this.clientID;
  }

  getClientName(idx) {
    let retVal = "unknown";
    this.ClientData.clientList.forEach((element) => {
      if (element.id == idx) {
        retVal = element.name;
        return retVal;
      }
    });
    return retVal;
  }

  getClientDetails() {
    return this.ClientData.clientDetails[this.clientName];
  }

  currentTransaction: any;
  setCurrentTransaction(trans) {
    this.currentTransaction = trans;
  }
  getCurrentTransaction() {
    return this.currentTransaction;
  }

  // getParishName(selectedParishID) {
  //   if (this.fullParishList.length == 0 || this.fullParishList.length == 1) {
  //     return "?? Parish";
  //   }
  //   if (this.parishLookup == undefined){
  //     this.parishLookup = {};
  //     // create the map if it doesn't exist.
  //     this.fullParishList.map( (el) => {
  //       this.parishLookup[el.ID] = el;
  //     });
  //   }
  //   return this.parishLookup[selectedParishID].name;
  // }

  getParishNameStatic(id){    
    return this.AllParishData.filter(x => x.ID == id );
    //return ParishData[id].name;    
  }

  getParishStaticData(clientID) {
    debugger;
    if (clientID == 5) {
      let val = this.AllParishData.filter(
        (x) => x.diocese == clientID && x.entityType == 0
      );
      val.push(this.AllParishData.filter((x) => x.ID == 50050)[0]);
      val.push(this.AllParishData.filter((x) => x.ID == 50060)[0]);
      return val;
    } else {
      return this.AllParishData.filter((x) => x.diocese == clientID);
    }
  }

  async getParishName(selectedParishID) {
    if (this.fullParishList.length == 0) {
      if (selectedParishID >= 17000 && selectedParishID <= 18000) {
        await this.setDioceseString("brokenbay");
      } else if (selectedParishID >= 0 && selectedParishID <= 500) {
        await this.setDioceseString("adelaide");
      } else if (selectedParishID >= 50000 && selectedParishID <= 53000) {
        await this.setDioceseString("adelaide");
      }

      console.log(
        "should have got parish list " + JSON.stringify(this.fullParishList)
      );
    }

    // if (selectedParishID > 5000 && selectedParishID < 7000) {
    //   return this.getSchoolNameFromID(selectedParishID);
    // }
    if (this.fullParishList) {
      if (this.fullParishList.length == 0 || this.fullParishList.length == 1) {
        return "?? Parish";
      }
    }
    if (this.parishLookup == undefined) {
      this.parishLookup = {};
      // create the map if it doesn't exist.
      this.fullParishList.map((el) => {
        this.parishLookup[el.ID] = el;
      });
    }
    let nm = "";
    try {
      nm = this.parishLookup[selectedParishID].name;
    } catch (ex) {
      // not a parish so try school name
      return nm;
    }
    return nm;
  }

  getParishNameFast(selectedParishID) {
    try {
      if (
        (selectedParishID > 50000 && selectedParishID < 70000) ||
        selectedParishID == 17000
      ) {
        //return this.getSchoolNameFromID(selectedParishID);
        if (this.objectIsEmpty(this.otherEntityIDLookup)) {
          this.otherEntityIDLookup = JSON.parse(
            localStorage.getItem("otherEntityIDLookup")
          );
        }
        return this.otherEntityIDLookup[selectedParishID].name;
      }
      if (this.objectIsEmpty(this.parishLookup)) {
        this.parishLookup = JSON.parse(localStorage.getItem("parishLookup"));
      }
      return this.parishLookup[selectedParishID].name;
    } catch (ex) {
      return "";
    }
  }

  cardURL: string =
    "https://paynow-sandbox.pmnts.io/v2/TESTdexterity/ABC123/AUD/10.00/6119a6df1db4209011f6ed2e7c5b35d1?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&layout=v2&return_target=_self&logo_url=https://dxts.com.au/images/banner_adelaide.png&postmessage=true";
  cardURLProd: string =
    "https://paynow.pmnts.io/v2/catholicendowmentsa/ABC123/AUD/10.00/16a1084b672b37eb20cad960af0efd7a?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&return_target=_self&postmessage=true";

  // This method will return the URL for the card iFrame. The url will depend on the clientID.
  getCardURL() {
    if (environment.production) {
      return this.cardURLProd;
    } else {
      return this.cardURL;
    }
  }

  getRandomPsalm() {
    let ran = Math.floor(Math.random() * this.psalms.length);
    return this.psalms[ran];
  }

  ///v1/parish/accounts/:diocese
  getParishList(dioceseId) {
    // returns the parishes for the diocese.
    return new Promise((resolve, reject) => {
      resolve(this.fullParishList);
      // let re = null;
      // let url= this.SERVER_URL + "v1/parish/accounts/5";

      // //let header = this.getHeaders(token);
      // let data = null;
      // console.log("getting parish list from server")        ;
      // this.http.get(url,  {headers: new HttpHeaders({
      //   'Content-Type':  'application/json'
      //   //'Authorization': this.authToken
      // })
      // }).subscribe(res => {
      //   console.log("Got parish list" + res) ;
      //   resolve(res);
      // });
    });
  }

  // getParishListFB() {
  //   return new Promise((resolve, reject) => {
  //     resolve(true)
  //   });
  // }

  getParishListFB() {
    // returns the parishes for the diocese.
    return new Promise((resolve, reject) => {
      var docRef = this.firestore.collection("appdata").doc(this.clientName);

      docRef.get().subscribe(function (doc) {
        if (doc.exists) {
          console.log("Document data:", doc.data());
          let data = doc.data();
          localStorage.setItem("parishes", JSON.stringify(data.parishes));
          localStorage.setItem("special", JSON.stringify(data.special));
          //this.getParishName(10);
          this.specialCollectionDetails = data.special;
          resolve(data);
        } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
          reject("no such document. failed to retrieve app data");
        }
      });
    });
  }

  getChurchList(ids: number[]) {
    if (!ids || ids.length < 1) return;
    return new Promise((resolve, reject) => {
      //TODO Switch API URL
      let url = this.WORSHIP_SERVER_URL + `church?ids=${ids.join()}`;
      //let url = "https://australia-southeast1-d2u-all-dev.cloudfunctions.net/worship-api/church?ids=" + `${ids.join()}`;
      let data = null;
      console.log("Fetching church details with ids");
      this.http
        .get(url, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            //'Authorization': this.authToken
          }),
        })
        .subscribe((res) => {
          console.log(
            "Fetched from new functions for church" + JSON.stringify(res)
          );
          if (res == 0) {
            resolve(null);
            return;
          }
          resolve(res);
        });
    });
  }

  getWorshipTimesForChurch(id: number) {
    if (!id) return;
    return new Promise((resolve, reject) => {
      //TODO Switch API URL
      let url = this.WORSHIP_SERVER_URL + `church/${id}/worship_times`;
      let data = null;
      console.log("Fetching worship times with church id");
      this.http
        .get(url, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            //'Authorization': this.authToken
          }),
        })
        .subscribe((res) => {
          console.log(
            "Fetched from new functions for church" + JSON.stringify(res)
          );
          if (res == 0) {
            resolve(null);
            return;
          }
          resolve(res);
        });
    });
  }

  addNewWorshipTime(
    type: number,
    churchId: number,
    time: string,
    day: number,
    weekNo: number,
    notes: string,
    language: string,
    endTime: string,
    diocese: number
  ) {
    debugger;
    if (isNaN(type) || !churchId || isNaN(day) || !time ) return;
    return new Promise((resolve, reject) => {
      //TODO Switch API URL
      let url = this.WORSHIP_SERVER_URL + `church/${churchId}/worship_times`;
      let data = null;
      console.log("Fetching worship times with church id");
      this.http
        .post(
          url,
          {
            data: {
              Type: type,
              Time: time,
              Day: day,
              WeekNo: weekNo,
              Notes: notes,
              Language: language,
              EndTime: endTime,
              Diocese: diocese,
            },
          },
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              //'Authorization': this.authToken
            }),
          }
        )
        .subscribe((res) => {
          console.log(
            "Fetched from new functions for church" + JSON.stringify(res)
          );
          if (res == 0) {
            resolve(null);
            return;
          }
          resolve(res);
        });
    });
  }

  updateWorshipTime(
    id: number,
    type: number,
    churchId: number,
    time: string,
    day: number,
    weekNo: number,
    notes: string,
    language: string,
    endTime: string,
    diocese: number
  ) {
    if (isNaN(type) || !churchId || isNaN(day) || !time ) return;
    return new Promise((resolve, reject) => {
      //TODO Switch API URL
      let url = this.WORSHIP_SERVER_URL + `worship_times/${id}`;
      let data = null;
      console.log("Updating worship item with id");
      this.http
        .patch(
          url,
          {
            updatedFields: {
              Type: type,
              Time: time,
              Day: day,
              WeekNo: weekNo,
              Notes: notes,
              Language: language,
              EndTime: endTime,
              Diocese: diocese,
              ChurchID: churchId,
            },
          },
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              //'Authorization': this.authToken
            }),
          }
        )
        .subscribe((res) => {
          console.log("Updated worship time" + JSON.stringify(res));
          if (res == 0) {
            resolve(null);
            return;
          }
          resolve(res);
        });
    });
  }

  updateChurchDetails(
    id: number,
    name: string,
    email: string,
    address: string,
    lat: any,
    lon: any,
    fax: any,
    phone: any,
    priest: any,
    website: string,
    deanery: string,
    url: any,
    diocese: number
  ) {
    if (!id) return;
    return new Promise((resolve, reject) => {
      //TODO Switch API URL
      let url = this.WORSHIP_SERVER_URL + `church/${id}`;
      let data = null;
      console.log("Updating church details with id");
      this.http
        .patch(
          url,
          {
            updatedFields: {
              name,
              email,
              address,
              lat,
              lon,
              fax,
              phone,
              priest,
              website,
              deanery,
              url,
              diocese
            },
          },
          {
            headers: new HttpHeaders({
              "Content-Type": "application/json",
              //'Authorization': this.authToken
            }),
          }
        )
        .subscribe((res) => {
          console.log("Updated church details" + JSON.stringify(res));
          if (res == 0) {
            resolve(null);
            return;
          }
          resolve(res);
        });
    });
  }

  deleteWorshipTime(id: number) {
    if (!id) return;
    return new Promise((resolve, reject) => {
      //TODO Switch API URL
      let url = this.WORSHIP_SERVER_URL + `worship_times/${id}`;
      let data = null;

      this.http
        .delete(url, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            //'Authorization': this.authToken
          }),
        })
        .subscribe((res) => {
          console.log("Deleted worship item succesfully");
          if (res == 0) {
            resolve(null);
            return;
          }
          resolve(res);
        });
    });
  }

  getSpecialCollection() {
    let sp = localStorage.getItem("special");

    this.specialCollectionDetails = JSON.parse(sp);
    return this.specialCollectionDetails;
  }

  getSpecialCollectionZ(dioceseId) {
    return new Promise((resolve, reject) => {
      resolve(this.fullParishList);

      let url = this.SERVER_URL + "v1/collections/other/5";

      //let header = this.getHeaders(token);
      let data = null;
      console.log("getting parish list from server");
      this.http
        .get(url, {
          headers: new HttpHeaders({
            "Content-Type": "application/json",
            //'Authorization': this.authToken
          }),
        })
        .subscribe((res) => {
          console.log("Got third collection " + JSON.stringify(res));
          if (res == 0) {
            resolve(null);
            return;
          }
          resolve(res[0]);
        });
    });
  }

  getCurrentSpecialCollection(dioceseId) {
    //return null into contract.
    return null;
  }

  getFriendlyNextChargeString(rruleStr: any, plusOne?: boolean) {
    let today = new Date();
    let tomorrow = new Date();
    tomorrow.setDate(today.getDate() + 1);

    console.time("Time this");
    let nextDate;

    if (plusOne) {
      nextDate = rrulestr(rruleStr).after(tomorrow);
    } else {
      nextDate = rrulestr(rruleStr).after(today);
    }

    console.log(nextDate);
    let now = moment().hour(0).min(0);
    let next = moment(nextDate);
    let friendlyDate = next.format("Do MMM");
    let diff = next.diff(now, "days"); // 1
    let nextString = "Next payment in " + diff + " days(" + friendlyDate + ")";
    if (diff == 0) {
      nextString = "Next payment is today (" + friendlyDate + ")";
    }
    if (diff == 1) {
      nextString = "Next payment is tomorrow (" + friendlyDate + ")";
    }
    if (diff > 30) {
      let newDiff = next.diff(now, "weeks");
      nextString =
        "Next payment in " + newDiff + " weeks (" + friendlyDate + ")";
    }
    return nextString;
  }

  objectIsEmpty(obj) {
    for (var prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        return false;
      }
    }

    return JSON.stringify(obj) === JSON.stringify({});
  }  

  getSettlementDate(payoutDate){
    let moment = require('moment-business-days'); // https://www.npmjs.com/package/moment-business-days

    // convert the date input, into 3 business days prior to run the report. .. need to check sat and sunday as input dates.. 
    console.log("calculating date.." + payoutDate);
    moment.locale('en-au');
    moment.updateLocale('en-au', {
        holidays: this.publicHolsNSW,
        holidayFormat: 'YYYYMMDD',
        workingWeekdays: [1, 2, 3, 4, 5]
     });
     debugger;
     return moment(payoutDate, 'YYYY-MM-DD').businessSubtract(3).format('YYYY-MM-DD');
  }

  getPayoutDate(settlementDate){
    let moment = require('moment-business-days'); // https://www.npmjs.com/package/moment-business-days

    // convert the date input, into 3 business days prior to run the report. .. need to check sat and sunday as input dates.. 
    console.log("calculating date.." + settlementDate);
    moment.locale('en-au');
    moment.updateLocale('en-au', {
        holidays: this.publicHolsNSW,
        holidayFormat: 'YYYYMMDD',
        workingWeekdays: [1, 2, 3, 4, 5]
     });
     debugger;
     return moment(settlementDate, 'YYYY-MM-DD').businessAdd(3).format('YYYY-MM-DD');
  }
  
    
}
