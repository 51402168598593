import { CurrencyPipe } from '@angular/common';
import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { RRule, RRuleSet, rrulestr } from 'rrule'

@Component({
  selector: 'recurring-options',
  templateUrl: './recurring-options.component.html',
  styleUrls: ['./recurring-options.component.scss'],
})
export class RecurringOptionsComponent implements OnInit {
  recurringValue = "not set";
  // This component makes a request but it can't actually delete a hero.
  @Output() valueSelected = new EventEmitter<object>();
  btn:string = "none-selected";
  currentDate = new Date();
  today:Date = new Date()
  tomorrow:Date = new Date(this.today);
  isOneOff:boolean = false;
  selectedDate =  new Date().toISOString();
  maxDateStr = (this.currentDate.getFullYear() + 1) + "-" + (this.currentDate.getMonth()+1).toString().padStart(2,"0") + "-" + this.currentDate.getDate().toString().padStart(2,"0") ;  
  minDateStr = (this.currentDate.getFullYear()) + "-" + (this.currentDate.getMonth()+1).toString().padStart(2,"0") + "-" + (this.currentDate.getDate() + 1).toString().padStart(2,"0");
  
  finalStr:string = this.changeDateFormat(this.currentDate);
  rRuleStr: string = "FREQ=YEARLY;INTERVAL=1;WKST=SU";
  descStr: string = "recurring not selected";
  selectedDateString = ""; 

  constructor() { 
    console.log("DATE is " + this.maxDateStr);  
    this.tomorrow.setDate(this.tomorrow.getDate() + 1);
    this.selectedDate = this.today.toISOString(); // making today default
    this.selectedDateString = this.selectedDate.substr(0,10);
    this.generateRRules();
  }

  checkMinDate(one){ // a recurring date must start from tomorrow as the minimum.
    let dateST = new Date(this.selectedDate);
    if (one) {      
        this.selectedDate = this.today.toISOString();      
    }
    else
    {
      if (dateST < this.tomorrow )
      {
        this.selectedDate = this.tomorrow.toISOString();
      }
    }
  }

  generateRRules() {
    // Cherry-pick only some options from an rrule:

    let fre: any = null;
    let intv = 1;
    let desc = "one-off";
    let dateST = new Date(this.selectedDate);
    let days =  ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let months = ["January","Febuary", "March","April","May","June","July","August","September","October","November","December"]
    switch(this.btn)
    {
      case "weekly":
        this.isOneOff = false;
        this.checkMinDate(true);
        fre = RRule.WEEKLY;
        desc = "Weekly every " + days[dateST.getDay()] + " starting on the " + dateST.getDate() + "/" +  months[dateST.getMonth()] + "/" + dateST.getFullYear();
        
        break;
      case "bi-weekly":
        this.isOneOff = false;
        this.checkMinDate(true);
        fre = RRule.WEEKLY;
        intv = 2;
        desc = "Fortnightly every " + days[dateST.getDay()] + " starting on the " + dateST.getDate() + "/" + months[dateST.getMonth()]  + "/" + dateST.getFullYear();
        break;
      case "monthly":
        this.isOneOff = false;
        this.checkMinDate(true);
        fre = RRule.MONTHLY;
        desc = "Monthly every " + (dateST.getDate()).toString() + " day of the month starting on the " +  (dateST.getDate()).toString() + "/" + months[dateST.getMonth()]  + "/" + dateST.getFullYear();
        break;
      case "yearly":
        this.isOneOff = false;
        this.checkMinDate(true);
        fre = RRule.YEARLY;
        desc = "Yearly every " + dateST.getDate() + "/" + months[dateST.getMonth()] + " staring on the " +  dateST.getDate() + "/" + months[dateST.getMonth()]  + "/" + dateST.getFullYear();
        break;      
      case "one-off":
        this.isOneOff = true;
        this.checkMinDate(true);
        fre = "one-off";
        desc = "one-off on "  +  this.today.getDate() + "/" + months[this.today.getMonth()]  + "/" + this.today.getFullYear();
        //this.selectedDate = this.today.toISOString();
        //this.minDateStr = (this.currentDate.getFullYear()) + "-" + (this.currentDate.getMonth()+1).toString().padStart(2,"0") + "-" + this.currentDate.getDate().toString().padStart(2,"0");
        break;    
    }

    this.descStr = desc;

    
    dateST.setHours(11);
    dateST.setMinutes(0);
    dateST.setSeconds(0);

    let rule = null
    if (fre != "one-off" ) {
      if ( fre != null) {
        rule = new RRule({
          freq: fre,
          interval: intv,
          dtstart: dateST
        });
      }
      
    }
    
    if (rule == null){
      rule = "none-selected";
    }
    

    // if (fre != null) {
    //   RRule.optionsToString({
    //     freq: rule.options.freq,
    //     interval: rule.options.interval,
    //     dtstart: rule.options.dtstart
    //   });
    // }
    // else{
    //   rule = "one-off";
    // }
    
    


    console.log("Rule generated is: " + rule.toString());
    return rule.toString();

    // console.log(rule.all(function (date, i){return i < 5}));
    

    // let store  = rule.toString();

    // let rules = rrulestr(store);
    // let dateF = new Date(2019,5,5);
    // console.log( rules.all(function (dateF, i){return i < 5}));


    // console.log(rule.before(new Date(), true));
    // // "DTSTART:20120201T093000Z\nRRULE:FREQ=WEEKLY;"
  }
  
  p(){
    this.recurringValue = this.generateRRules();
    console.log("RECURRING STRING is :"  + this.recurringValue);
    console.log(this.descStr);
    let myobj = {
      "text": this.descStr,
      "rrule": this.recurringValue,
      "startDate": this.selectedDateString 
    }
    this.valueSelected.emit(myobj);
    
  }

  changeDateFormat(selDate){
      return selDate.getFullYear().toString() + (selDate.getMonth() + 1).toString().padStart(2, '0') + (selDate.getDate() + 1).toString().padStart(2, '0') + "T110000Z";
  }

  dateChanged(data) {
    console.log('date change' + JSON.stringify(data));
    let selDate = new Date(data.detail.value);
    this.selectedDate = selDate.toISOString();
    // if (selDate.getMonth() == 2 && selDate.getDate() == 29 && ){
    //   alert("Please note, you have selected a date which will only happen every ")
    // }
    let buildString = this.changeDateFormat(selDate);
    this.finalStr  = buildString;
    this.selectedDateString = this.selectedDate.substr(0,10);

    this.p();

  }

  setWeekly() {
    this.btn = "weekly";
    let day = new Date().getDay() + 2;
    this.recurringValue = "on the " + day + " day of the week";    
    this.rRuleStr = "FREQ=WEEKLY;INTERVAL=1;WKST=SU";
    this.p();
  }

  setBiWeekly() {
    this.btn = "bi-weekly";
    this.recurringValue = "every 2 weeks";
    this.rRuleStr = "FREQ=WEEKLY;INTERVAL=2;WKST=SU";
    this.p();
  }

  setMonthly() {
    this.btn = "monthly";
    let day = new Date().getDate() + 1;
    this.rRuleStr = "FREQ=MONTHLY;INTERVAL=1;WKST=SU";
    this.recurringValue = "on the " + day + " day of the month";
    this.p();
  }

  SetYearly() {
    this.btn = "yearly";
    this.rRuleStr = "FREQ=YEARLY;INTERVAL=1;WKST=SU";
    this.recurringValue = "yearly";
    this.p();
  }

  SetOneOff() {
    this.btn = "one-off";
    this.recurringValue = "one-off";
    this.p();
  }
  ngOnInit() {}

}
