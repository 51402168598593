import { AdminUserService } from "../../providers/admin-user.service";
import { DataServiceService } from "../../providers/data-service.service";
import { UserService } from "../../providers/user.service";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  ViewChild,
} from "@angular/core";
import { IonicSelectableComponent } from "ionic-selectable";
// https://www.npmjs.com/package/ionic-selectable

@Component({
  selector: "church-selector",
  templateUrl: "./church-selector.component.html",
  styleUrls: ["./church-selector.component.scss"],
})
export class ChurchSelectorComponent implements OnInit {
  @Input() parish: number; // determine which church list to get.
  @Input() setChurch: any;
  @Output() selected = new EventEmitter<number>();
  @Output() type = new EventEmitter<string>();
  @Output() churchSelected = new EventEmitter<any>();
  selectedChurchId: number = -1;
  selectedChurch: any = {};
  churches: any[] = [];

  myUserService: UserService;

  @ViewChild("searchComponent", { static: false })
  portComponent: IonicSelectableComponent;

  constructor(
    userService: UserService,
    public appData: DataServiceService,
    adminUser: AdminUserService
  ) {
    debugger;
    this.fullChurchList = appData.getParishStaticData(adminUser.getClientID());
    this.selectedChurch = null;
  }

  // setChurch(churchObj) {
  //     this.selectedChurch = churchObj;
  // };

  ngOnInit() {}

  churchChanged($event) {
    this.selectedChurch = $event.value;
    this.churchSelected.emit($event.value);
  }

  openSearch($event) {
    this.portComponent.open();
  }

  fullChurchList: any = [
    {
      ID: 9999999999,
      name: "Parishes are loading..",
      first: 100014015,
      second: 100000009,
      other: 100013525,
      abn: 11083216579,
      active: 1,
      diocese: 5,
    },
  ];
}
