import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'network-check',
  templateUrl: './network-check.component.html',
  styleUrls: ['./network-check.component.scss'],
})
export class NetworkCheckComponent implements OnInit {
  title = 'connectionDetector';
  status = 'ONLINE'; //initializing as online by default
  isConnected = true;

  constructor() {
    // this.conn.monitor().subscribe(isConnected => {
    //   console.log("CONN MONITOR SUB");
    //   this.isConnected = isConnected;
    //   if(this.isConnected){
    //     this.status = "ONLINE";
    //   } else {
    //     this.status = "OFFLINE"
    //   }
    //   alert(this.status);
    // });

   }

  ngOnInit() {}

}
