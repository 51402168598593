import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'giving-date-range',
  templateUrl: './giving-date-range.component.html',
  styleUrls: ['./giving-date-range.component.scss'],
})
export class GivingDateRangeComponent implements OnInit {


  // Better dat arange picker
  // https://openbase.com/js/ngx-daterangepicker-material


  @Output() startDateChanged = new EventEmitter<string>();
  @Output() endDateChanged = new EventEmitter<string>();

  start: Date = new Date();
  end: Date = new Date();
  buttonText = "Change Date";
  showPickers: boolean = false;
  constructor() { }

  ngOnInit() {}

  // https://stackoverflow.com/questions/23593052/format-javascript-date-as-yyyy-mm-dd
  formatDate(date) {
    var d = new Date(date),
        month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    return [year, month, day].join('-');
}


  startDateSelected($event) {
    console.log("start seelceted.... " );
    this.start = $event;
    this.startDateChanged.emit(this.formatDate(this.start));//.toISOString().substr(0, 10));
    console.log($event);
  } 

  endDateSelected($event) {
    this.end = $event;
    this.endDateChanged.emit(this.formatDate(this.end));//.toISOString().substr(0,10));
  }

  togglePickers() {
    this.showPickers = !this.showPickers;
    if (this.showPickers) {
      this.buttonText = "Hide";
    }
    else 
    {
      this.buttonText = "Change Date";
    }
  }

}

