import { LoadingController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../environments/environment';
import * as firebase from 'firebase';
@Injectable({
  providedIn: 'root'
})
export class ReportingServiceService {
  SERVER_URL =  environment.SERVER; // "http://localhost:8020/"
  //SERVER_URL =  "http://localhost:8020/";
  //SERVER_URL = "https://platform.goodgiving.com.au/"

  loading: any ;
 constructor(private http:HttpClient, public loadingController: LoadingController) { 
  
}
  
  private httpGet(url: string, token: any, callback: any) {
    this.presentLoading();
    this.http.get(url, {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',// 'attachment/csv',
        'Authorization': token
      })
    }).subscribe(res => {
      this.loading.dismiss();
      callback(res);
    });
  }

  updateServerURL(clientID){
    if (clientID == 5 && environment.production){
      this.SERVER_URL = "https://platform.goodgiving.com.au/"  ;
    }
  }

  async presentLoading() {
    this.loading = await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Please wait while the report generates .. ',
      duration: 2000
    });
    await this.loading.present();
    const { role, data } = await this.loading.onDidDismiss();    
  }

  private getCSV(url){
    var hiddenElement = document.createElement('a');

    hiddenElement.href = 'data:attachment/csv,' + encodeURI(url);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'myFile.csv';
    hiddenElement.click();
  }

  getUserTransactionsAPI(limit, token, callback){         
    let url= this.SERVER_URL + "v1/transactions/" + limit;
    console.log("Getting user transactions usring the URL " + this.SERVER_URL);      
    this.httpGet(url, token, callback);
  }

  getDioceseReport(clientid, startDate, endDate, token, callback){
    
       let url = this.SERVER_URL + "v1/reporting/" + clientid + "/" + startDate + "/" + endDate;
 
      this.httpGet(url, token, x => {
          callback(x);
      });      
  }

   getParishReport(clientID, parishID, startDate, endDate, token, callback){
      this.updateServerURL(clientID);
       let url = this.SERVER_URL + "v1/reporting/parish/" + clientID + "/" + parishID + "/" + startDate + "/" + endDate;
       this.httpGet(url, token,  x => {
         callback(x);
       });
       //window.open(url);
      }

    getAdelaideParish1st2ndReport(start,end, callback) {
      let functions  = firebase.app().functions("australia-southeast1");
      
      var getReport = functions.httpsCallable('getAdelaideParishMonthlyData');
      getReport({startDate:start, endDate:end}).then((result) => {
        callback(result);
      });
    }
    
    getParish1st2ndReport(clientID, parishID, startDate, endDate, token)
    {
      this.updateServerURL(clientID);
      return new Promise((resolve, reject ) => {
        let url = this.SERVER_URL + "v1/reporting/donors/1st2nd/" + clientID + "/" + parishID + "/" + startDate + "/" + endDate;
        this.httpGet(url, token,  x => {
          resolve({parishid: parishID, data: x});
        });
      })
      
    }
    getParishSpecialReport(clientID, parishID, startDate, endDate, token)
    {
      this.updateServerURL(clientID);
      return new Promise((resolve, reject ) => {
        let url = this.SERVER_URL + "v1/reporting/donors/special/" + clientID + "/" + parishID + "/" + startDate + "/" + endDate;
        this.httpGet(url, token,  x => {
          resolve({parishid: parishID, data: x});
        });
      });
    }

    getRecurringSummaryReport( clientID, token, callback){
     // this.updateServerURL(clientID);
      let url = this.SERVER_URL + "v1/reporting/summary/recurring/monthly/" + clientID ;
      this.httpGet(url, token,  x => {
        callback(x);
      });
      //window.open(url);
    }

    getTransactionsForRecurringUser(email, token, callback){      
        let url = this.SERVER_URL + "v1/reporting/recurring/user/" + email;
        this.httpGet(url, token,  x => {
          callback(x);
        });
    }

  getRecurringReport(clientID, parishID, token, callback){
    
    let url = this.SERVER_URL + "v1/reporting/recurring/" +clientID + "/" + parishID ;
    this.httpGet(url, token,  x => {
      callback(x);
    });
    //window.open(url);
    }

    getSettlementReport(clientID, startDate, endDate, token, callback){
      let url = this.SERVER_URL + "v1/reporting/settlements/" + clientID + "/" + startDate + "/" + endDate;
      this.httpGet(url, token,  x => {
        callback(x);
      });
      //window.open(url);
     }


     getParishSummaryReport(parishID, startDate, endDate, token, callback){
      let url = this.SERVER_URL + "v1/reporting/parishes/summary/" + parishID + "/" + startDate + "/" + endDate;
      this.httpGet(url, token,  x => {
        callback(x);
      });
     }

     getSummaryReport(clientID, token, callback){
      let url = this.SERVER_URL + "v1/reporting/summary/" + clientID ;
      this.httpGet(url, token,  x => {
        callback(x);
      });
      //window.open(url);
     }

     getParishTransactionsAll(parishID, response, startDate, endDate, token, callback){
       if (response == null) {
         response = 0;
       }
      let url = this.SERVER_URL + "v1/reporting/parish/one/" + parishID + "/" + response + "/" + startDate + "/" + endDate;
      this.httpGet(url, token,  x => {
        callback(x);
      });
     }

     getPWBBTransactionsAll(parishID, response, startDate, endDate, token, callback){
      if (response == null) {
        response = 0;
      }
     let url = this.SERVER_URL + "v1/reporting/parish/pwbb/" + parishID + "/" + response + "/" + startDate + "/" + endDate;
     this.httpGet(url, token,  x => {
       callback(x);
     });
    }

     getParishRecurringAll(parishID, token, callback){
        let url = this.SERVER_URL + "v1/transactions/parish/recurring/all/" + parishID;
        this.httpGet(url, token,  x => {
          callback(x);
        });
     }     

    getOtherEntitiesTransactionsAll(parishID, response, startDate, endDate, token, callback){
      if (response == null) {
        response = 0;
      }

      let url = ""; 
      if (parishID == 50060)
      {
        url = this.SERVER_URL + "v1/reporting/cc/" + response + "/" + startDate + "/" + endDate;
      }
      else if (parishID == 50050)
      {
        url = this.SERVER_URL + "v1/reporting/prf/" + response + "/" + startDate + "/" + endDate;
      }
      else if (parishID == 17000)
      {
        url = this.SERVER_URL + "v1/reporting/pwbb/" + response + "/" + startDate + "/" + endDate;
      }

      this.httpGet(url, token,  x => {
        callback(x);
      });
    }

    getOtherEntitiesRecurringAll(parishID, token, callback){

      let url = this.SERVER_URL + "v1/transactions/other/recurring/all/" + parishID;      
      this.httpGet(url, token,  x => {
        callback(x);
      });
   }     
     
}
