import { DataServiceService } from './../../providers/data-service.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'abn',
  templateUrl: './abn.component.html',
  styleUrls: ['./abn.component.scss'],
})
export class AbnComponent implements OnInit {
  clientObj:any;
  constructor(public dataService:DataServiceService) { 
    

  }

  ngOnInit() {

  }

}
