import { DataServiceService } from './../../providers/data-service.service';

import { Component, OnInit, ɵCompiler_compileModuleSync__POST_R3__, Input, EventEmitter, Output } from '@angular/core';
import { PayMethodService } from '../../providers/pay-method.service';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserService } from '../../providers/user.service';

import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

@Component({
  selector: 'save-card-only',
  templateUrl: './save-card-only.component.html',
  styleUrls: ['./save-card-only.component.scss'],
})
export class SaveCardOnlyComponent implements OnInit {
  makeDefaultCard: boolean = true;
  //showAddCard: boolean; 
  showNotLoggedin: boolean = false;
  @Input() showAddCard: boolean = true;
  @Output() cardAdded = new EventEmitter<string>();
  showList: boolean = false;
  cardSecureImage: string = "../../../assets/img/cardiconssm.png";
  // cardURL:string="https://paynow-sandbox.pmnts.io/v2/TESTdexterity/ABC123/AUD/10.00/6119a6df1db4209011f6ed2e7c5b35d1?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&layout=v2&return_target=_self&logo_url=https://dxts.com.au/images/banner_adelaide.png&postmessage=true"
  // cartURLProd:string="https://paynow.pmnts.io/v2/catholicendowmentsa/ABC123/AUD/10.00/16a1084b672b37eb20cad960af0efd7a?l=v2&tokenize_only=true&iframe=true&show_email=false&show_extras=false&button_text=Save&return_target=_self&postmessage=true"
  iframeURL:SafeResourceUrl ;
  constructor(
    //private window: Window,  // commenting this in will introduce a build error in production. circular dependency. 
    // public router: Router,
    public payMethodService: PayMethodService,
    public afAuth: AngularFireAuth,
    public userService: UserService,
    private sanitizer: DomSanitizer,
    public dataService: DataServiceService
  ) { 
      let url = dataService.getCardURL();
      console.log("$$$$$$$$$$$$$$$$$$$$$$" + url);
      this.iframeURL = this.sanitizer.bypassSecurityTrustResourceUrl(url);      
      if (this.afAuth.auth.currentUser)
      {
        this.showAddCard = true;
      }
      else 
      {
        this.showNotLoggedin = true;
        this.showAddCard = false;
      }
    
  }

  reloadIframe() {

    //document.getElementById("addCardFrame").doc .ownerDocument.location.reload(true);
  }

  toggleShowCard() {
    this.showAddCard = !this.showAddCard;
    if(this.showAddCard == true){
      this.cardAdded.emit(null);
    }
  }
  ionViewWillEnter() {
    if (this.afAuth.auth.currentUser)
    {
      //console.log("current user token is " + this.afAuth.auth.currentUser.uid);
      //console.log(this.payMethodService.get_cc_User(this.afAuth.auth.currentUser.uid));
    }
    else 
    {
      alert("user is not logged in.. can't add card.")
    }

   
   }

  ngAfterViewInit() {
    //this.pms = payMethodService;  
    

    if (window.addEventListener) { 
      
      window.addEventListener("message", (event) => {
        //messageListener(event) {
          //console.log("messageListener called");
          //if (event.origin !== window.paymentHost) {
          //  return;
          //}
          if (event.data.message == "form.invalid")
          {
            // do nothing as the iframe handles the validation. 
            return; 
          }
          //alert("messageListenet called");
          // Older browsers will have a query-string style data payload
          // Whereas newer browsers will have an object
          var payload = event.data;
          if (typeof event.data == 'string') {
            if (/\[object/i.test(event.data)) {
                alert("Sorry, it looks like there has been a problem communicating with your browser..."); // Raised if the serialization failed
            }
            // Deserialize into an object
            var pairs = payload.split("&");
            payload = {};
            for (var i = 0; i < pairs.length; i++) {
                var element = pairs[i];
                var kv = element.split("=");
                payload[kv[0]] = kv[1];
            }
          }
          if ('data' in payload) {
            var payload = payload.data;
          }
          //switch (payload.message) {
          switch (event.data.message) {
            case "sca.session":
              // In case of SCA/3DS2, get a JWT token (payload.data.jwt) to initiate
              // the 3DS2 authentication process.
              // FatZebraSCA.js should be included in the HTML code before proceeding.
              // Refer to Authentication (SCA/3DS2).
            case "transaction.complete":
              // Handle the transaction complete message.
              // Payload will be in e.data.data (eg. payload.data.r == 1)
              let cData = event.data.data;
              let ccObj = {
                token: cData.token,
                card_expiry: cData.card_expiry,
                card_holder: cData.card_holder,
                card_number: cData.card_number,            
                card_type: cData.card_type
              };
              let selected = this.userService.get_User_selectedCC();
              let makeDefault = false;
              if ( selected == undefined || selected == "")
              {
                makeDefault = true; // there is no default card at the moment, so make it default
              }
              else
              {
                // there is already a default, lets check if we need to replace the defaul with this new card.
                if (this.makeDefaultCard)
                {
                  makeDefault = true;
                }
              }
              //this.writeCCDetails();
              this.payMethodService.create_cc_User(this.afAuth.auth.currentUser.uid, ccObj,makeDefault ).then(resp => {
                // console.log(resp);
                this.showAddCard = false;
                this.makeDefaultCard = false;
                this.cardAdded.emit(ccObj.token);
              })
              .catch(error => {
                console.log(error);
              });;
    
              
            case "transaction.processing":
              // Handle the processing of the transaction - implementation optional.
              return true;
            case "transaction.cancelled":
              // Handle the transaction being cancelled (i.e. show message, re-display the window etc).
              return true;
          }
      
      });
      //console.log("message listener added ");
    }
    else { 
      //window.attachEvent("onmessage", this.messageListener); 
    }
  }
  
  ngOnInit() {
    
  }

  resizeIframe(iframe) {
    iframe.height = iframe.contentWindow.document.body.scrollHeight + "px";
  }

}
