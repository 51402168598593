import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  currentCustomerID:string = "3VgJbOyAwyTxEH5wHZcp3Vlji0E3";
  constructor() { }

  setCustomer(id:string) {
    this.currentCustomerID = id;
  }

  getCustomer(){
    return this.currentCustomerID;
  }

  unsetCustomer() {
    this.currentCustomerID == null;
  }

  customerSelected() {
    if (this.currentCustomerID == null) return false;
    return true;
  }

  // setRecurruing


  //getCustomerCards


  // set CustomerDetails.

}
